import React from 'react';
import { Button, Icon, Modal } from 'rsuite';
import styles from './Confirm.module.scss';
import PropTypes from 'prop-types';

const Confirm = ({ onAgree = () => {}, onDisagree = () => {}, isOpen = false, title = '' }) => (
    <Modal
        backdrop={'static'}
        className={styles.Confirm}
        show={isOpen}
        size={'xs'}
        onHide={onDisagree}
    >
        <Modal.Body>
            {title && <h6 className={styles.title}>{title}</h6>}
            <div className={styles.message}>
                <Icon icon={'remind'} className={styles.remind} />
                {'Are you sure you want to proceed?'}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onAgree} appearence={'primary'}>
                Ok
            </Button>
            <Button onClick={onDisagree} appearence={'subtle'}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
);

Confirm.propTypes = {
    onAgree: PropTypes.func,
    onDisagree: PropTypes.func,
    isOpen: PropTypes.bool,
    title: PropTypes.bool,
};

export default Confirm;
