import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
    Checkbox,
    FlexboxGrid,
    Uploader,
    Icon,
} from 'rsuite';
import { API } from '../../../helpers/api';

const CreateArticleAuthors = () => {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [webLink, setWebLink] = useState('');
    const [bio, setBio] = useState('');
    const [isDefaultLogo, setIsDefaultLogo] = useState(false);
    const [photo, setPhoto] = useState('');
    const [errorPhotoUrl, errorSetPhotoUrl] = useState('');
    const history = useHistory();

    const validateFields = () => {
        if (!name) {
            setNameError(true);
        } else {
            setNameError(false);
        }

        if (!isDefaultLogo) {
            if (!photo) {
                errorSetPhotoUrl(true);
            } else {
                errorSetPhotoUrl(false);
            }
        }

        if (!name || (!isDefaultLogo && !photo)) {
            return false;
        }

        return true;
    };

    const createAuthor = async () => {
        if (!validateFields()) {
            return;
        }
        setNameError('');
        errorSetPhotoUrl('');

        const newAuthors = await API({
            url: 'v1/blog/article-authors',
            method: 'POST',
            data: {
                name,
                webLink,
                bio,
            },
        });

        if (photo) {
            const requestBody = new FormData();
            requestBody.append('photo', photo);
            requestBody.append('author', JSON.stringify(newAuthors.data.author));

            await API({
                url: 'v1/blog/article-authors-save-avatar',
                method: 'POST',
                data: requestBody,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    type: 'formData',
                },
            });
        }

        history.push('/blog/article-authors');
    };

    const handleSelectPicture = (files) => {
        if (files.length > 0) {
            return setPhoto(files[0].blobFile);
        }
        setPhoto(null);
    };

    return (
        <div>
            <h2>Create new Author</h2>
            <br />
            <Form>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl value={name} onChange={(text) => setName(text)} name="name" />
                    {nameError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Web Link</ControlLabel>
                    <FormControl
                        value={webLink}
                        onChange={(text) => setWebLink(text)}
                        name="webLink"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Photo Url</ControlLabel>
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                    {errorPhotoUrl && (
                        <div style={{ color: '#ca2d2d' }}>This field is required</div>
                    )}
                </FormGroup>

                <FlexboxGrid>
                    <Checkbox
                        value={isDefaultLogo}
                        onClick={() => setIsDefaultLogo(!isDefaultLogo)}
                    >
                        Set default Logo
                    </Checkbox>
                </FlexboxGrid>
                <br />

                <FormGroup>
                    <ControlLabel>Biography</ControlLabel>
                    <FormControl value={bio} onChange={(text) => setBio(text)} name="bio" />
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createAuthor} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
};

export default CreateArticleAuthors;
