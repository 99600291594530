import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { slugify } from 'transliteration';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
    Checkbox,
    FlexboxGrid,
    Uploader,
    Icon,
} from 'rsuite';
import TextEditor from '../../../components/common/TextEditor/TextEditor';
import BoxUploader from '../../../components/common/BoxUploader/BoxUploader';
import { API } from '../../../helpers/api';
import styles from './EditArticle.module.scss';

const EditArticle = () => {
    const [categories, setCategories] = useState([]);

    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [detailText, setDetailText] = useState('');

    const [categoryPicked, pickCategory] = useState('');
    const [categoryError, setCategoryError] = useState(false);

    const [authorId, pickAuthor] = useState(false);
    const [authorError, setAuthorError] = useState(false);

    const [isMain, setIsMain] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [previewPicture, setPreviewPicture] = useState('');
    const [fileList, setFileList] = useState([]);
    const [authors, setAuthors] = useState([]);

    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            const articleAuthors = await API({ url: 'v1/blog/article-authors', method: 'GET' });

            articleAuthors.data.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
            });

            API({ url: 'v1/blog/categories/admin', method: 'GET' }).then((res) => {
                res.data.forEach((item) => {
                    item.label = item.title;
                    item.value = item.id;
                });

                const urlArr = history.location.pathname.split('/');
                const lastChunkUrl = urlArr[urlArr.length - 1];

                API({ url: `v1/blog/articles/${lastChunkUrl}`, method: 'GET' }).then((res2) => {
                    if (res2.data) {
                        setTitle(res2.data.title);
                        setSubtitle(res2.data.subtitle);
                        setDescription(res2.data.description);
                        setDetailText(res2.data.detailText);
                        setIsMain(res2.data.isMain);
                        setIsActive(res2.data.isActive);

                        res.data.unshift(res2.data.category);

                        if (!res2.data.author) {
                            articleAuthors.data.unshift({});
                        } else {
                            articleAuthors.data.unshift(res2.data.author);
                        }
                        setAuthors(articleAuthors.data);

                        setCategories(res.data);
                        pickCategory(res2.data.category);

                        if (res2.data.author) {
                            pickAuthor(res2.data.author.id);
                        }

                        if (res2.data.previewPicture) {
                            setFileList([
                                {
                                    name: '1png',
                                    fileKey: 1,
                                    url: res2.data.previewPicture.path,
                                },
                            ]);

                            setPreviewPicture('exist');
                        }
                    }
                });
            });
        };

        fetchData();
    }, []);

    const removePickImage = () => {
        setFileList([]);
        // setPreviewPicture('');
    };

    const validateFields = () => {
        if (!categoryPicked) {
            setCategoryError(true);
            return false;
        }

        return true;
    };

    const createArticleRequestBody = (article) => {
        const requestBody = new FormData();

        if (article.id) {
            requestBody.append('id', article.id);
        }

        requestBody.append('title', article.title);
        requestBody.append('subtitle', article.subtitle);
        requestBody.append('description', article.description);
        requestBody.append('detailText', article.detailText);
        requestBody.append('categoryId', article.categoryId);
        requestBody.append('isMain', article.isMain);
        requestBody.append('isActive', article.isActive);
        requestBody.append('alias', article.alias);
        requestBody.append('author', article.author);

        if (article.previewPicture) {
            requestBody.append('previewPicture', article.previewPicture);
        } else {
            requestBody.append('previewPicture', JSON.stringify({ path: '' }));
        }

        if (article.previewPictureId) {
            requestBody.append('previewPictureId', article.previewPictureId);
        }

        return requestBody;
    };

    const editArticle = async () => {
        if (!validateFields()) {
            return;
        }
        setCategoryError(false);

        const data = {
            title,
            subtitle,
            description,
            alias: slugify(title),
            isMain,
            isActive,
            detailText,
            categoryId: categoryPicked.id,
            author: authorId,
        };

        if (previewPicture) {
            data['previewPicture'] = previewPicture;
        }

        const urlArr = history.location.pathname.split('/');
        const lastChunkUrl = urlArr[urlArr.length - 1];
        data.id = lastChunkUrl;

        await API({
            url: 'v1/blog/articles',
            method: 'PUT',
            data: createArticleRequestBody(data),
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        alert('Updated');
    };

    const handleSelectPicture = (files) => {
        if (files.length > 0) {
            return setPreviewPicture(files[0].blobFile);
        }
        setPreviewPicture(null);
    };

    const pickCategoryFunc = (title) => {
        setCategoryError(false);
        const pickedCategory = categories.filter((category) => category.title === title);
        if (pickedCategory[0]) {
            pickCategory(pickedCategory[0]);
        }
    };

    const pickAuthorFunc = (name) => {
        // setCategoryError(false);
        const pickedAuthor = authors.filter((category) => category.name === name);
        if (pickedAuthor[0]) {
            pickAuthor(pickedAuthor[0].id);
        }
    };

    return (
        <div className={styles.articleBox}>
            <BoxUploader />

            <h2>Update Article</h2>
            <br />
            <Form>
                <FormGroup>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Subtitle</ControlLabel>
                    <FormControl
                        value={subtitle}
                        onChange={(text) => setSubtitle(text)}
                        name="subtitle"
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Description</ControlLabel>
                    <FormControl
                        value={description}
                        onChange={(text) => setDescription(text)}
                        name="description"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Category</ControlLabel>

                    <select onChange={(id) => pickCategoryFunc(id.target.value)}>
                        {categories.map((item, index) => {
                            return <option key={index}>{item.title}</option>;
                        })}
                    </select>
                    {categoryError && (
                        <div style={{ color: '#ca2d2d' }}>This field is required</div>
                    )}
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Author</ControlLabel>

                    <select onChange={(id) => pickAuthorFunc(id.target.value)}>
                        {authors.map((item, index) => {
                            return <option key={index}>{item.name}</option>;
                        })}
                    </select>
                    {authorError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>
                <br />
                <br />

                <FlexboxGrid>
                    <input
                        className={styles.checkBox}
                        checked={isActive}
                        onClick={() => setIsActive(!isActive)}
                        type="checkbox"
                    />
                    <div>Active</div>
                </FlexboxGrid>

                {!fileList.length ? (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        onRemove={removePickImage}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                ) : (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        fileList={fileList}
                        onRemove={removePickImage}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                )}

                <br />
                <br />

                <ControlLabel>Text</ControlLabel>
                <TextEditor text={detailText} onChange={setDetailText} />
                <br />
                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={editArticle} appearance="primary">
                            Update
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default EditArticle;
