import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button } from 'rsuite';
import { API } from '../../../helpers/api';
import { getLastUrlPath } from '../../../helpers/getLastUrlPath';

const EditTemplate = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const history = useHistory();

    const getTemplate = () => {
        API({
            url: `v1/journal/admin/templates/${getLastUrlPath()}`,
            method: 'GET',
        }).then(({ data }) => {
            setTitle(data.title);
            setDescription(data.description);
        });
    };

    useEffect(() => {
        getTemplate();
    }, []);

    const changeTemplate = async () => {
        await API({
            url: 'v1/journal/admin/templates/',
            method: 'PUT',
            data: {
                id: Number(getLastUrlPath()),
                title,
                description,
            },
        });

        history.push('/journal/list');
    };

    return (
        <div>
            <h2>Edit template</h2>
            <Form>
                <FormGroup>
                    <br />
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    <br />
                    <br />
                    <ControlLabel>Placeholder</ControlLabel>
                    <FormControl
                        value={description}
                        onChange={(text) => setDescription(text)}
                        name="placeholder"
                    />
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={changeTemplate} appearance="primary">
                            Change
                        </Button>
                        <Button appearance="default" onClick={history.goBack}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
};

export default EditTemplate;
