import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
    Checkbox,
    FlexboxGrid,
    Uploader,
    Icon,
} from 'rsuite';
import { API } from '../../../helpers/api';

const EdidtArticleAuthors = () => {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const [webLink, setWebLink] = useState('');
    const [id, setId] = useState('');

    const [bio, setBio] = useState('');
    const [isDefaultLogo, setIsDefaultLogo] = useState(false);

    const [previewPicture, setPreviewPicture] = useState('');
    const [errorPhotoUrl, errorSetPhotoUrl] = useState('');
    const [fileList, setFileList] = useState([]);

    const history = useHistory();

    useEffect(() => {
        const authorId =
            history.location.pathname.split('/')[history.location.pathname.split('/').length - 1];

        API({ url: `v1/blog/article-authors/${authorId}` }).then((res) => {
            setName(res.data.name);
            setWebLink(res.data.webLink);
            setBio(res.data.bio);
            setId(res.data.id);
            setPreviewPicture('exist');

            setFileList([
                {
                    name: '1png',
                    fileKey: 1,
                    url: res.data.photoUrl,
                },
            ]);
        });
    }, []);

    const removePickImage = () => {
        setFileList([]);
        setPreviewPicture('onDelete');
    };

    const validateFields = () => {
        if (!name) {
            setNameError(true);
        } else {
            setNameError(false);
        }

        if (!isDefaultLogo) {
            if (!previewPicture) {
                errorSetPhotoUrl(true);
            } else {
                errorSetPhotoUrl(false);
            }
        }

        if (!name || (!isDefaultLogo && !previewPicture)) {
            return false;
        }

        return true;
    };

    const editAuthor = async () => {
        if (!validateFields()) {
            return;
        }
        setNameError('');
        errorSetPhotoUrl('');

        const updateData = {
            name,
            web_link: webLink,
            bio,
            id,
            photo: previewPicture,
        };

        await API({
            url: 'v1/blog/article-authors',
            method: 'PUT',
            data: createAuthorRequestBody(updateData),
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        history.push('/blog/article-authors');
    };

    const handleSelectPicture = (files) => {
        if (files.length > 0) {
            return setPreviewPicture(files[0].blobFile);
        }
        // setPreviewPicture(null);
    };

    const createAuthorRequestBody = (author) => {
        const requestBody = new FormData();

        if (author.id) {
            requestBody.append('id', author.id);
        }

        requestBody.append('name', author.name);
        requestBody.append('web_link', author.web_link);
        requestBody.append('bio', author.bio);

        if (author.photo) {
            requestBody.append('previewPicture', author.photo);
        } else {
            requestBody.append('previewPicture', '');
        }

        if (author.previewPictureId) {
            requestBody.append('previewPictureId', author.previewPictureId);
        }

        return requestBody;
    };

    return (
        <div>
            <h2>Edit Author</h2>
            <br />
            <Form>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl value={name} onChange={(text) => setName(text)} name="name" />
                    {nameError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Web Link</ControlLabel>
                    <FormControl
                        value={webLink}
                        onChange={(text) => setWebLink(text)}
                        name="webLink"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Photo Url</ControlLabel>
                    {!fileList.length ? (
                        <Uploader
                            onChange={handleSelectPicture}
                            listType="picture"
                            accept=".png,.jpg,.jpeg,.webp"
                            autoUpload={false}
                            onRemove={removePickImage}
                        >
                            <button>
                                <Icon icon="camera-retro" size="lg" />
                            </button>
                        </Uploader>
                    ) : (
                        <Uploader
                            onChange={handleSelectPicture}
                            listType="picture"
                            accept=".png,.jpg,.jpeg,.webp"
                            autoUpload={false}
                            fileList={fileList}
                            onRemove={removePickImage}
                        >
                            <button>
                                <Icon icon="camera-retro" size="lg" />
                            </button>
                        </Uploader>
                    )}
                </FormGroup>

                <FlexboxGrid>
                    <Checkbox
                        value={isDefaultLogo}
                        onClick={() => setIsDefaultLogo(!isDefaultLogo)}
                    >
                        Set default Logo
                    </Checkbox>
                </FlexboxGrid>
                <br />

                <FormGroup>
                    <ControlLabel>Biography</ControlLabel>
                    <FormControl value={bio} onChange={(text) => setBio(text)} name="bio" />
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={editAuthor} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
};

export default EdidtArticleAuthors;
