import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
    Checkbox,
    Uploader,
    Icon,
    FlexboxGrid,
} from 'rsuite';
import { API } from '../../../helpers/api';

const CreateAffiliate = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [link, setLink] = useState('');
    const [order, setOrder] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [linkError, setLinkError] = useState(false);
    const [previewPicture, setPreviewPicture] = useState('');
    const [previewPictureLogo, setPreviewPictureLogo] = useState('');
    const [isActive, setIsActive] = useState(false);

    const history = useHistory();

    const validateFields = () => {
        setTitleError(false);
        setLinkError(false);

        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }

        if (!link) {
            setLinkError(true);
            isError = true;
        }

        if (isError) {
            return false;
        }

        return true;
    };

    const createAffiliateRequestBody = (affiliate) => {
        const requestBody = new FormData();

        requestBody.append('title', affiliate.title);
        requestBody.append('subtitle', affiliate.subtitle);
        requestBody.append('link', affiliate.link);
        requestBody.append('order', affiliate.order);
        requestBody.append('buttonText', affiliate.buttonText);

        if (affiliate.image) {
            requestBody.append('image', affiliate.image);
        }
        if (affiliate.imageLogo) {
            requestBody.append('imageLogo', affiliate.imageLogo);
        }
        requestBody.append('isActive', affiliate.isActive);

        return requestBody;
    };

    const createAffiliate = async () => {
        try {
            if (!validateFields()) {
                return;
            }
            setTitleError(false);
            setLinkError(false);

            const data = {
                title,
                subtitle,
                link,
                order,
                isActive,
                buttonText,
            };

            if (previewPicture) {
                data['image'] = previewPicture;
            }

            if (previewPictureLogo) {
                data['imageLogo'] = previewPictureLogo;
            }

            await API({
                url: 'v1/affiliate',
                method: 'POST',
                data: createAffiliateRequestBody(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    type: 'formData',
                },
            });

            history.push('/affiliate/list');
        } catch (e) {
            alert(JSON.stringify(e.message));
            console.error(e);
        }
    };

    const handleSelectPicture = (files) => {
        if (files.length > 0) {
            return setPreviewPicture(files[0].blobFile);
        }
        setPreviewPicture(null);
    };

    const handleSelectPictureLogo = (files) => {
        if (files.length > 0) {
            return setPreviewPictureLogo(files[0].blobFile);
        }
        setPreviewPictureLogo(null);
    };

    return (
        <div>
            <h2>Create Affiliate</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Description</ControlLabel>
                    <FormControl
                        value={subtitle}
                        onChange={(text) => setSubtitle(text)}
                        name="subtitle"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Link</ControlLabel>
                    <FormControl value={link} onChange={(text) => setLink(text)} name="link" />
                    {linkError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Order</ControlLabel>
                    <FormControl value={order} onChange={(text) => setOrder(text)} name="order" />
                </FormGroup>

                <FlexboxGrid>
                    <Checkbox checked={isActive} onClick={() => setIsActive(!isActive)}>
                        Active
                    </Checkbox>
                </FlexboxGrid>

                <Uploader
                    onChange={handleSelectPicture}
                    listType="picture"
                    accept=".png,.jpg,.jpeg,.webp"
                    autoUpload={false}
                    multiple={false}
                    disabled={previewPicture}
                >
                    <button>
                        <Icon icon="camera-retro" size="lg" />
                    </button>
                </Uploader>

                <br />
                <br />

                <div>Logo</div>

                <Uploader
                    onChange={handleSelectPictureLogo}
                    listType="picture"
                    accept=".png,.jpg,.jpeg,.webp"
                    autoUpload={false}
                    multiple={false}
                    disabled={previewPictureLogo}
                >
                    <button>
                        <Icon icon="camera-retro" size="lg" />
                    </button>
                </Uploader>

                <FormGroup>
                    <ControlLabel>Button text</ControlLabel>
                    <FormControl
                        value={buttonText}
                        onChange={(text) => setButtonText(text)}
                        name="order"
                    />
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createAffiliate} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default CreateAffiliate;
