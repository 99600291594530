import { useEffect, useState } from 'react';
import { ButtonToolbar, Icon, IconButton, Table } from 'rsuite';
import { useHistory } from 'react-router-dom';
import styles from './Verification.module.scss';
import { API } from '../../helpers/api';
import { ChallengesList } from '../../modules/Challenges';
import Confirm from '../../components/common/Confirm/Confirm';
const { Column, Cell, HeaderCell } = Table;

const Challenges = () => {
    const history = useHistory();
    const [challenges, setChallenges] = useState([]);
    const [deleteChallengeId, setDeleteChallengeId] = useState();
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getChallenges();
    }, []);

    const getChallenges = async () => {
        const { data } = await API({ url: 'v1/challenges/admin/admin', method: 'GET' });

        data.challenges.forEach((item) => {
            if ('height' in item) {
                item.type = 'goal';
            }
            if ('routine' in item) {
                item.type = 'habit';
            }
        });

        if (data && data.challenges) {
            setChallenges(data.challenges);
        }
    };

    const modalAgreeHandler = () => {
        API({ url: 'v1/challenges/admin/' + deleteChallengeId, method: 'DELETE' }).then(() => {
            getChallenges();
        });
        setModalOpen(false);
    };

    const deleteChallenge = (id) => {
        setDeleteChallengeId(id);
        setModalOpen(true);
    };

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const pendingChallenges = challenges.filter((item) => item.share === 'pending');
    const acceptChallenges = challenges.filter((item) => item.share === 'accept');
    const rejectedChallenges = challenges.filter((item) => item.share === 'decline');

    return (
        <div>
            <h2>Awaiting verification</h2>

            <Table style={{ width: 1200 }} data={pendingChallenges} autoHeight rowHeight={55}>
                <Column width={50} verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>

                <Column width={120} verticalAlign={'middle'}>
                    <HeaderCell>Type</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return <div>{rowData.type?.toUpperCase()}</div>;
                        }}
                    </Cell>
                </Column>

                <Column width={400} verticalAlign={'middle'}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey={'name'} />
                </Column>

                <Column width={400} flexGrow={1} verticalAlign={'middle'}>
                    <HeaderCell>Description</HeaderCell>
                    <Cell dataKey={'routine'} />
                </Column>

                <Column width={400} flexGrow={1} verticalAlign={'middle'}>
                    <HeaderCell>Status</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return (
                                <div className={styles.statusCont}>
                                    <div className={styles.pending}>Pending</div>
                                </div>
                            );
                        }}
                    </Cell>
                </Column>

                <Column width={80} fixed={'right'} verticalAlign={'middle'}>
                    <HeaderCell>Action</HeaderCell>

                    <Cell>
                        {(rowData) => {
                            const type = rowData.type === 'goal' ? 'goal' : 'habit';
                            const handleEdit = () => {
                                const path = [history.location.pathname, type, rowData.id];
                                history.push(path.join('/'));
                            };

                            return (
                                <ButtonToolbar>
                                    <IconButton
                                        onClick={handleEdit}
                                        icon={<Icon icon={'pencil'} />}
                                        color={'blue'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                </ButtonToolbar>
                            );
                        }}
                    </Cell>
                </Column>
            </Table>

            <br />
            <br />

            <h2>Challenges</h2>
            <ChallengesList />

            <br />
            <br />

            <h2>Rejected</h2>

            <Table style={{ width: 1200 }} data={rejectedChallenges} autoHeight rowHeight={55}>
                <Column width={50} verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>

                <Column width={120} verticalAlign={'middle'}>
                    <HeaderCell>Type</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return <div>{rowData.type?.toUpperCase()}</div>;
                        }}
                    </Cell>
                </Column>

                <Column width={400} verticalAlign={'middle'}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey={'name'} />
                </Column>

                <Column width={400} flexGrow={1} verticalAlign={'middle'}>
                    <HeaderCell>Description</HeaderCell>
                    <Cell dataKey={'routine'} />
                </Column>

                <Column width={400} flexGrow={1} verticalAlign={'middle'}>
                    <HeaderCell>Status</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return (
                                <div className={styles.statusCont}>
                                    <div className={styles.rejected}>Rejected</div>
                                </div>
                            );
                        }}
                    </Cell>
                </Column>

                <Column width={80} fixed={'right'} verticalAlign={'middle'}>
                    <HeaderCell>Action</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            const type = rowData.type === 'goal' ? 'goal' : 'habit';
                            const handleEdit = () => {
                                const path = [history.location.pathname, type, rowData.id];
                                history.push(path.join('/'));
                            };

                            return (
                                <ButtonToolbar>
                                    <IconButton
                                        onClick={handleEdit}
                                        icon={<Icon icon={'pencil'} />}
                                        color={'blue'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                    {/* <IconButton
                                        onClick={() => deleteChallenge(rowData.id)}
                                        icon={<Icon icon={'close-circle'} />}
                                        color={'red'}
                                        title={'Open'}
                                        size={'sm'}
                                    /> */}
                                </ButtonToolbar>
                            );
                        }}
                    </Cell>
                </Column>
            </Table>

            <br />
            <br />
            <br />
            <br />
            <br />

            <Confirm
                onAgree={modalAgreeHandler}
                onDisagree={modalDisagreeHandler}
                isOpen={isModalOpen}
            />
        </div>
    );
};

export default Challenges;
