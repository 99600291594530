import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import PropTypes from 'prop-types';
import htmlEditButton from 'quill-html-edit-button';
import styles from './TextEditor.module.scss';
import './quill.snow.css';

Quill.register({
    'modules/htmlEditButton': htmlEditButton,
});

const TextEditor = ({ text = '', onChange = () => {} }) => {
    return (
        <ReactQuill
            className={styles.TextEditor}
            value={text}
            onChange={onChange}
            // theme={'snow'}
            modules={{
                toolbar: {
                    container: [
                        [{ header: [2, 3, 4, 5, 6, null] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ size: [] }],
                        [{ align: [] }],
                        // [{'indent': '-1'}, {'indent': '+1'}],
                        [{ color: [] }, { background: [] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['image', 'link'],
                        ['clean'],
                    ],
                    handlers: {},
                },
                htmlEditButton: {},
            }}
        />
    );
};

TextEditor.propTypes = {
    text: PropTypes.string,
    onChange: PropTypes.func,
};

export default TextEditor;
