import { useEffect, useState } from 'react';
import { ButtonToolbar, Icon, IconButton, Table } from 'rsuite';
import { API } from '../../../helpers/api';
const { Column, Cell, HeaderCell } = Table;

const Comments = () => {
    const [comments, setComments] = useState([]);
    useEffect(() => {
        const getComments = async () => {
            const { data } = await API({ url: 'v1/blog/all-comments', method: 'GET' });
            setComments(data.comments);
        };
        getComments();
    }, []);

    const deleteComment = async (commentId) => {
        const { data } = await API({ url: `v1/blog/comment/${commentId}`, method: 'DELETE' });

        if (data) {
            let copyComments = [...comments];
            copyComments = copyComments.filter((item) => item.id !== commentId);
            setComments(copyComments);
        }
    };

    return (
        <div>
            <h2>Comments</h2>

            <Table style={{ width: 1400 }} data={comments} autoHeight rowHeight={55}>
                <Column width={200} verticalAlign={'middle'}>
                    <HeaderCell>Author</HeaderCell>
                    <Cell dataKey={'name'} />
                </Column>

                <Column flexGrow={1} verticalAlign={'middle'}>
                    <HeaderCell>Text</HeaderCell>
                    <Cell dataKey={'text'} />
                </Column>

                <Column width={80} fixed={'right'} verticalAlign={'middle'}>
                    <HeaderCell>DELETE</HeaderCell>

                    <Cell>
                        {(rowData) => {
                            return (
                                <ButtonToolbar>
                                    <IconButton
                                        onClick={() => deleteComment(rowData.id)}
                                        icon={<Icon icon={'trash'} />}
                                        color={'red'}
                                        title={'Remove'}
                                        size={'sm'}
                                    />
                                </ButtonToolbar>
                            );
                        }}
                    </Cell>
                </Column>
            </Table>
        </div>
    );
};

export default Comments;
