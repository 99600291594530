import axios from 'axios';
import { BASE_URL } from '../constants';

export const API = ({ url, method, headers, data, params }) => {
    try {
        return axios({
            method,
            url: BASE_URL + url,
            headers,
            timeout: 120000,
            data,
            params,
        });
    } catch (e) {
        alert(JSON.stringify(e.message));
        console.error(e);
    }
};

axios.defaults.headers.common['authtoken'] = localStorage.getItem('token');
axios.defaults.headers.common['refreshtoken'] = localStorage.getItem('refreshToken');
