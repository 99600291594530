import React from 'react';
import styles from './PreLoader.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PreLoader = ({
  variant = 'thin',
  className = '',
}) => {
  const classes = classNames(
    styles.PreLoader,
    styles[variant],
    className,
  );

  return (
    <div className={classes} />
  );
};

PreLoader.propTypes = {
  variant: PropTypes.oneOf(['thin', 'thick']),
  className: PropTypes.string,
};

export default PreLoader;
