import { FlexboxGrid, Input, Button } from 'rsuite';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';
import { useState } from 'react';

const AiAssistant = () => {
    const [content, setContent] = useState('');
    const [responseText, setResponseText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getResponseFromAI = async () => {
        setIsLoading(true);
        try {
            const { data } = await API({
                url: `v1/open-ai?content=${content}`,
                method: 'GET',
            });
            setResponseText(data.content);
            setIsLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    console.log(responseText);

    return (
        <div className={styles.container}>
            <FlexboxGrid>
                <h2>Open AI Assistant</h2>
            </FlexboxGrid>
            <Input value={content} onChange={setContent} />
            <br />
            <Button disabled={isLoading} appearance="primary" onClick={getResponseFromAI}>
                Get Response
            </Button>
            <br />
            <br />
            <plaintext>{responseText}</plaintext>

            <br />
            <br />
        </div>
    );
};

export default AiAssistant;
