import { useEffect, useState } from 'react';
import { ButtonToolbar, Icon, IconButton, Table } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import Confirm from '../../components/common/Confirm/Confirm';
import styles from './ChallengesList.module.scss';
const { Column, Cell, HeaderCell } = Table;

const ChallengesList = () => {
    const history = useHistory();
    const [challenges, setChallenges] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [deleteChallengeId, setDeleteChallengeId] = useState();

    useEffect(() => {
        getChallenges();
    }, []);

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const getChallenges = async () => {
        try {
            const { data } = await API({ url: 'v1/challenges/admin/list', method: 'GET' });
            if (data && data.challenges) {
                setChallenges(data.challenges);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const modalAgreeHandler = () => {
        API({ url: 'v1/challenges/admin/' + deleteChallengeId, method: 'DELETE' }).then(() => {
            getChallenges();
        });
        setModalOpen(false);
    };

    const deleteChallenge = (id) => {
        setDeleteChallengeId(id);
        setModalOpen(true);
    };

    return (
        <div>
            <Table style={{ width: 1200 }} data={challenges} autoHeight rowHeight={55}>
                <Column width={50} verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>

                <Column width={120} verticalAlign={'middle'}>
                    <HeaderCell>Type</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return <div>{rowData.type.toUpperCase()}</div>;
                        }}
                    </Cell>
                </Column>

                <Column width={400} verticalAlign={'middle'}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey={'name'} />
                </Column>

                <Column width={400} flexGrow={1} verticalAlign={'middle'}>
                    <HeaderCell>Description</HeaderCell>
                    <Cell dataKey={'description'} />
                </Column>

                <Column width={400} flexGrow={1} verticalAlign={'middle'}>
                    <HeaderCell>Status</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return (
                                <div className={styles.statusCont}>
                                    <div className={styles.accept}>Accept</div>
                                </div>
                            );
                        }}
                    </Cell>
                </Column>

                <Column width={80} fixed={'right'} verticalAlign={'middle'}>
                    <HeaderCell>Action</HeaderCell>

                    <Cell>
                        {(rowData) => {
                            const handleEdit = () => {
                                const path = ['list', rowData.id];
                                history.push(path.join('/'));
                            };

                            return (
                                <ButtonToolbar>
                                    <IconButton
                                        onClick={handleEdit}
                                        icon={<Icon icon={'pencil'} />}
                                        color={'blue'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                    <IconButton
                                        onClick={() => deleteChallenge(rowData.id)}
                                        icon={<Icon icon={'close-circle'} />}
                                        color={'red'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                </ButtonToolbar>
                            );
                        }}
                    </Cell>
                </Column>
            </Table>

            <Confirm
                onAgree={modalAgreeHandler}
                onDisagree={modalDisagreeHandler}
                isOpen={isModalOpen}
            />
        </div>
    );
};

export { ChallengesList };
