import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { slugify } from 'transliteration';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button } from 'rsuite';
import { API } from '../../../helpers/api';

const EditCategory = () => {
    const [title, setTitle] = useState('');
    const [isView, setIsView] = useState(false);
    const [titleError, setTitleError] = useState('');
    const history = useHistory();

    const validateFields = () => {
        if (!title) {
            setTitleError(true);
            return false;
        }

        return true;
    };

    useEffect(() => {
        const urlArr = history.location.pathname.split('/');
        const lastChunkUrl = urlArr[urlArr.length - 1];

        API({ url: `v1/blog/article-categories/${lastChunkUrl}`, method: 'GET' }).then((res) => {
            if (res.data) {
                setTitle(res.data.title);
                setIsView(res.data.is_view);
            }
        });
    }, []);

    const updateCategory = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(true);

        const urlArr = history.location.pathname.split('/');
        const lastChunkUrl = urlArr[urlArr.length - 1];

        await API({
            url: 'v1/blog/article-categories',
            method: 'PUT',
            data: {
                title,
                alias: slugify(title),
                id: Number(lastChunkUrl),
            },
        });

        history.push('/blog/article-categories');
    };

    const changeIsView = async () => {
        const urlArr = history.location.pathname.split('/');
        const lastChunkUrl = urlArr[urlArr.length - 1];
        setIsView(!isView);
        await API({
            url: 'v1/blog/category-view',
            method: 'PUT',
            data: {
                isView: !isView,
                id: Number(lastChunkUrl),
            },
        });
    };

    return (
        <div>
            <h2>UpdateCategory</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                {/* <FlexboxGrid>
                    <Checkbox checked={isView} onClick={changeIsView}>
                        Visible
                    </Checkbox>
                </FlexboxGrid> */}

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={updateCategory} appearance="primary">
                            Update
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
};

export default EditCategory;
