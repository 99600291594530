import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from 'rsuite';
import Header from './components/common/Header/Header';
import Sidebar from './components/standart/Sidebar/Sidebar';
import Footer from './components/common/Footer/Footer';
import PreLoader from './components/common/PreLoader/PreLoader';
import LoginForm from './components/standart/LoginForm/LoginForm';
import { API } from './helpers/api';

import Articles from './pages/Blog/Articles';
import CreateArticle from './pages/Blog/CreateArticle';
import EditArticle from './pages/Blog/EditArticle';
import Categories from './pages/Blog/Categories';
import CreateCategory from './pages/Blog/CreateCategory';
import EditCategory from './pages/Blog/EditCategory';
import ArticleAuthors from './pages/Blog/ArticleAuthors';
import CreateArticleAuthors from './pages/Blog/CreateArticleAuthors';
import EditArticleAuthors from './pages/Blog/EditArticleAuthors';

import AffiliateList from './pages/Affiliate/List/AffiliateList';
import AffiliateCreate from './pages/Affiliate/Create/CreateAffiliate';
import AffiliateEdit from './pages/Affiliate/Edit/EditAffiliate';

import TemplatesList from './pages/Templates/List';
import TemplatesCreate from './pages/Templates/Create/';
import TemplatesEdit from './pages/Templates/Edit/';

import PromptList from './pages/Prompt/List/AffiliateList';
import PromptCreate from './pages/Prompt/Create/CreateAffiliate';
import PromptEdit from './pages/Prompt/Edit/EditAffiliate';
import InspirationList from './pages/Inspiration/List';
import CreateInspiration from './pages/Inspiration/Create';
import EditInspiraton from './pages/Inspiration/Edit';
import KarmaList from './pages/Karma/List';
import KarmaCreate from './pages/Karma/Create';
import KarmaEdit from './pages/Karma/Edit';
import TipList from './pages/Tips/List';
import TipCreate from './pages/Tips/Create';
import TipEdit from './pages/Tips/Edit';

import Comments from './pages/Blog/Comments/Comments';
import Challenges from './pages/Challenges/Verification';
import NewChallenge from './pages/Challenges/NewChallenge';
import ChallengesItem from './pages/Challenges/ChallengesItem';
import EditChallenge from './pages/Challenges/EditChallenge';
import Errors from './pages/Errors/list';

import ReportAccountList from './pages/Community/reported-account-list';
import ReportPostList from './pages/Community/reported-posts-list';
import ReportCommentList from './pages/Community/reported-comments-list';
import CommunityPosts from './pages/Community/posts';
import AddCommunityPost from './pages/Community/add-post';
import EditCommunityPost from './pages/Community/edit-post';

import UsersList from './pages/Users/list';
import CreateUser from './pages/Users/create';
import EditUser from './pages/Users/edit';
import AiAssistant from './pages/ai/assistant';

import 'rsuite/dist/styles/rsuite-default.css';
import { Challenges as ChallengesPage } from './pages/Challenges/Challenges';

const App = ({ children }, ...props) => {
    const [isAuth, changeIsAuth] = useState('wait');

    useEffect(() => {
        if (localStorage.getItem('token')) {
            API({
                url: 'v1/auth/admin',
                method: 'GET',
            })
                .then((res) => {
                    changeIsAuth(true);
                })
                .catch((e) => {
                    changeIsAuth(false);
                });
        } else {
            changeIsAuth(false);
        }
    }, []);

    const authorize = () => {
        changeIsAuth(true);
    };

    if (isAuth === 'wait') {
        return <PreLoader />;
    }

    if (!isAuth) {
        return <LoginForm authorize={authorize} />;
    }

    return (
        <div>
            <Container>
                <Header />
                <Container style={{ width: '100%' }}>
                    <Sidebar />
                    <Switch>
                        <Route exact path="/blog/articles">
                            <Articles />
                        </Route>
                        <Route exact path="/blog/articles/create">
                            <CreateArticle />
                        </Route>
                        <Route exact path="/blog/articles/edit/:id">
                            <EditArticle />
                        </Route>
                        <Route exact path="/blog/article-categories">
                            <Categories />
                        </Route>
                        <Route exact path="/blog/article-categories/create">
                            <CreateCategory />
                        </Route>
                        <Route exact path="/blog/article-categories/edit/:id">
                            <EditCategory />
                        </Route>
                        {/* article-authors */}
                        <Route exact path="/blog/article-authors">
                            <ArticleAuthors />
                        </Route>
                        <Route exact path="/blog/article-authors/create">
                            <CreateArticleAuthors />
                        </Route>
                        <Route exact path="/blog/article-authors/edit/:id">
                            <EditArticleAuthors />
                        </Route>
                        <Route exact path="/blog/comments">
                            <Comments />
                        </Route>
                        {/* affiliate */}
                        <Route exact path="/affiliate/list">
                            <AffiliateList />
                        </Route>
                        <Route exact path="/affiliate/create">
                            <AffiliateCreate />
                        </Route>
                        <Route exact path="/affiliate/edit/:id">
                            <AffiliateEdit />
                        </Route>
                        {/* users */}
                        <Route exact path="/users">
                            <UsersList />
                        </Route>
                        <Route exact path="/users/create">
                            <CreateUser />
                        </Route>
                        <Route exact path="/users/edit/:id">
                            <EditUser />
                        </Route>
                        {/* community */}
                        <Route exact path="/community/posts/">
                            <CommunityPosts />
                        </Route>
                        <Route exact path="/community/posts/create">
                            <AddCommunityPost />
                        </Route>
                        <Route exact path="/community/posts/edit/:id">
                            <EditCommunityPost />
                        </Route>
                        <Route exact path="/community/reported/accounts">
                            <ReportAccountList />
                        </Route>
                        <Route exact path="/community/reported/posts">
                            <ReportPostList />
                        </Route>
                        <Route exact path="/community/reported/comments">
                            <ReportCommentList />
                        </Route>
                        {/* challenges */}
                        <Route exact path="/challenges/list">
                            <ChallengesPage />
                        </Route>
                        <Route exact path="/challenges/new">
                            <NewChallenge />
                        </Route>
                        <Route exact path="/challenges/list">
                            <ChallengesPage />
                        </Route>
                        <Route exact path="/challenges/list/:id">
                            <EditChallenge />
                        </Route>
                        <Route exact path="/challenges/verification">
                            <Challenges />
                        </Route>
                        <Route exact path="/challenges/verification/:type/:id">
                            <ChallengesItem />
                        </Route>

                        {/* Prompts */}
                        <Route exact path="/prompt/list">
                            <PromptList />
                        </Route>
                        <Route exact path="/prompt/create">
                            <PromptCreate />
                        </Route>
                        <Route exact path="/prompt/edit/:id">
                            <PromptEdit />
                        </Route>

                        {/* Inspiration */}
                        <Route exact path="/inspiration/list">
                            <InspirationList />
                        </Route>
                        <Route exact path="/inspiration/create">
                            <CreateInspiration />
                        </Route>
                        <Route exact path="/inspiration/edit/:id">
                            <EditInspiraton />
                        </Route>

                        {/* Tips */}
                        <Route exact path="/tips/list">
                            <TipList />
                        </Route>
                        <Route exact path="/tips/create">
                            <TipCreate />
                        </Route>
                        <Route exact path="/tips/edit/:id">
                            <TipEdit />
                        </Route>

                        {/* Templates */}
                        <Route exact path="/journal/list">
                            <TemplatesList />
                        </Route>
                        <Route exact path="/journal/create">
                            <TemplatesCreate />
                        </Route>
                        <Route exact path="/journal/edit/:id">
                            <TemplatesEdit />
                        </Route>

                        {/* Karma */}
                        <Route exact path="/karma/list">
                            <KarmaList />
                        </Route>
                        <Route exact path="/karma/create">
                            <KarmaCreate />
                        </Route>
                        <Route exact path="/karma/edit/:id">
                            <KarmaEdit />
                        </Route>
                        {/* errors */}
                        <Route exact path="/system/error">
                            <Errors />
                        </Route>
                        <Route exact path="/ai/assistant">
                            <AiAssistant />
                        </Route>
                    </Switch>
                </Container>
                <Footer />
            </Container>
        </div>
    );
};

export default App;
