import styles from './BoxUploader.module.scss';
import React, { useState, useRef } from 'react';
import { Button, Uploader, Icon, Input } from 'rsuite';
import { API } from '../../../helpers/api';

const BoxUploader = () => {
    const [previewLink, setPreviewLink] = useState(false);
    const [link, setLink] = useState();
    const [maxWidth, setMaxWidth] = useState();

    const inputEl = useRef(null);

    const handleSelectLink = (files) => {
        if (files.length > 0) {
            return setPreviewLink(files[0].blobFile);
        }
        setPreviewLink(null);
    };

    const getLink = async () => {
        try {
            if (previewLink) {
                const requestBody = new FormData();
                requestBody.append('previewLink', previewLink);

                if (maxWidth) {
                    requestBody.append('maxWidth', maxWidth);
                }

                const res = await API({
                    url: 'v1/blog/preview-link',
                    method: 'POST',
                    data: requestBody,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'application/json',
                        type: 'formData',
                    },
                });

                setLink(res.data.link);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const removePickImage = () => {
        setPreviewLink(false);
    };

    return (
        <div className={styles.boxUploader}>
            <h5>Get link from your image</h5>
            <Uploader
                onChange={handleSelectLink}
                listType="picture"
                accept=".png,.jpg,.jpeg,.webp"
                autoUpload={false}
                disabled={previewLink}
                onRemove={removePickImage}
            >
                <button>
                    <Icon icon="camera-retro" size="lg" />
                </button>
            </Uploader>
            <br />
            <Button disabled={!previewLink} size="sx" onClick={getLink} appearance="primary">
                Get link
            </Button>

            <div className={styles.linkCont}>
                <Input ref={inputEl} style={{ marginTop: '6px' }} value={link} />
                {/* <Button size="xs" onClick={copyLinkToBuffer} color="green">
                    copy link
                </Button> */}
            </div>

            <div className={styles.maxWidthCont}>
                <strong>Max width</strong>
                <Input type="number" onChange={(e) => setMaxWidth(e)} value={maxWidth} />
            </div>

            <br />
            <ol>
                <li>Please do not insert the picture through the editor.</li>
                <li>First, upload the image to the form above and get the link.</li>
                <li>Then paste the link in the editor into square brackets and save it.</li>
                <li>Add pictures in this way one at a time!</li>
                <li>
                    Example <span>[your link here]</span>
                </li>
            </ol>
        </div>
    );
};

export default BoxUploader;
