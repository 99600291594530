import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
} from 'rsuite';
import { API } from '../../../helpers/api';

const CreateTemplate = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [nameError, setNameError] = useState(false);
    const history = useHistory();

    const validateFields = () => {
        setNameError(false);
        let isError = false;

        if (!name) {
            createTemplate(true);
            isError = true;
        }

        if (isError) return false;
        return true;
    };

    const createTemplate = async () => {
        if (!validateFields()) {
            return;
        }
        setNameError(false)


        await API({
            url: 'v1/journal/admin/templates',
            method: 'POST',
            data: {
                title: name,
                description,
            },
        });

        history.push('/journal/list');
    };

    return (
        <div>
            <h2>Create Template</h2>
            <Form>
                <FormGroup>
                <br />
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={name} onChange={(text) => setName(text)} name="title" />
                    <br />
                    <br />
                    <ControlLabel>Placeholder</ControlLabel>
                    <FormControl value={description} onChange={(text) => setDescription(text)} name="placeholder" />
                    {nameError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createTemplate} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
};

export default CreateTemplate;
