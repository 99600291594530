import { useEffect, useState } from 'react';
import { Table, FlexboxGrid } from 'rsuite';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';

const { Column, Cell, HeaderCell } = Table;
let take = 50;
let skip = 0;

const Users = () => {
    const [errors, setErrors] = useState();

    useEffect(() => {
        const getUser = async () => {
            const { data } = await API({
                url: `v1/logging?take=${take}&skip=${take * skip}`,
                method: 'GET',
            });

            setErrors(data.logs);
        };
        getUser();
    }, []);

    const normalizeDate = (date) => {
        return date.slice(0, 19).replace('T', ' ');
    };

    return (
        <div className={styles.container}>
            <FlexboxGrid>
                <h2>Errors</h2>
            </FlexboxGrid>
            <Table style={{ width: 1500 }} data={errors} autoHeight rowHeight={100}>
                <Column width={50} verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>

                <Column width={700} verticalAlign={'middle'}>
                    <HeaderCell>Message</HeaderCell>
                    <Cell dataKey={'message'} />
                </Column>

                <Column width={350} verticalAlign={'middle'}>
                    <HeaderCell>Path</HeaderCell>
                    <Cell dataKey={'path'} />
                </Column>

                <Column verticalAlign={'middle'}>
                    <HeaderCell>Status code</HeaderCell>
                    <Cell dataKey={'status_code'} />
                </Column>

                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Created</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            if (rowData.created_at) {
                                return <div>{normalizeDate(rowData.created_at)}</div>;
                            }
                        }}
                    </Cell>
                </Column>
            </Table>
        </div>
    );
};

export default Users;
