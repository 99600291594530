import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    ButtonToolbar,
    Button,
    Checkbox,
    Uploader,
    Icon,
    FlexboxGrid,
} from 'rsuite';
import { API } from '../../../helpers/api';
import { getLastUrlPath } from '../../../helpers/getLastUrlPath';

const EditAffiliate = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [link, setLink] = useState('');
    const [order, setOrder] = useState(0);
    const [buttonText, setButtonText] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [linkError, setLinkError] = useState(false);
    const [previewPicture, setPreviewPicture] = useState('');
    const [previewPictureLogo, setPreviewPictureLogo] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileListLogo, setFileListLogo] = useState([]);
    const [affiliateId, setAffiliateId] = useState([]);

    const history = useHistory();

    useEffect(() => {
        API({
            url: `v1/affiliate/${getLastUrlPath()}`,
            method: 'GET',
        }).then((res) => {
            if (res.data) {
                setTitle(res.data.title);
                setSubtitle(res.data.subtitle);
                setLink(res.data.link);
                setOrder(res.data.order);
                setIsActive(res.data.isActive);
                setAffiliateId(res.data.id);
                setButtonText(res.data.button_text);

                console.log('=res.data===========================');
                console.log(res.data);
                console.log('============================');

                if (res.data.image) {
                    setFileList([
                        {
                            name: '1png',
                            fileKey: 1,
                            url: res.data.image,
                        },
                    ]);
                }

                if (res.data.image_logo) {
                    setFileListLogo([
                        {
                            name: '1png',
                            fileKey: 1,
                            url: res.data.image_logo,
                        },
                    ]);
                }

                setPreviewPicture('exist');
                setPreviewPictureLogo('exist');
            }
        });
    }, []);

    const removePickImage = () => {
        setFileList([]);
        setPreviewPicture('');
    };

    const removePickImageLogo = () => {
        setFileListLogo([]);
        setPreviewPictureLogo('');
    };

    const validateFields = () => {
        setTitleError(false);
        setLinkError(false);

        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }

        if (!link) {
            setLinkError(true);
            isError = true;
        }

        if (isError) {
            return false;
        }

        return true;
    };

    const createAffiliateRequestBody = (affiliate) => {
        const requestBody = new FormData();

        if (affiliate.id) {
            requestBody.append('id', affiliate.id);
        }

        requestBody.append('title', affiliate.title);
        requestBody.append('subtitle', affiliate.subtitle);
        requestBody.append('link', affiliate.link);
        requestBody.append('order', affiliate.order);
        requestBody.append('image', affiliate.image);
        requestBody.append('imageLogo', affiliate.imageLogo);
        requestBody.append('isActive', affiliate.isActive);
        requestBody.append('buttonText', affiliate.buttonText);

        return requestBody;
    };

    const createAffiliate = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);
        setLinkError(false);

        const data = {
            title,
            subtitle,
            link,
            order,
            isActive,
            id: affiliateId,
            buttonText,
        };

        if (previewPicture) {
            data['image'] = previewPicture;
        }
        if (previewPictureLogo) {
            data['imageLogo'] = previewPictureLogo;
        }

        try {
            await API({
                url: 'v1/affiliate',
                method: 'PUT',
                data: createAffiliateRequestBody(data),
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    type: 'formData',
                },
            });
        } catch (e) {
            console.error(e);
        }

        history.push('/affiliate/list');
    };

    const handleSelectPicture = (files) => {
        if (files.length > 0) {
            return setPreviewPicture(files[0].blobFile);
        }
        setPreviewPicture(null);
    };

    const handleSelectPictureLogo = (files) => {
        if (files.length > 0) {
            return setPreviewPictureLogo(files[0].blobFile);
        }
        setPreviewPictureLogo(null);
    };

    return (
        <div>
            <h2>Edit Affiliate</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Description</ControlLabel>
                    <FormControl
                        value={subtitle}
                        onChange={(text) => setSubtitle(text)}
                        name="subtitle"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Link</ControlLabel>
                    <FormControl value={link} onChange={(text) => setLink(text)} name="link" />
                    {linkError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Order</ControlLabel>
                    <FormControl value={order} onChange={(text) => setOrder(text)} name="order" />
                </FormGroup>

                <FlexboxGrid>
                    <Checkbox
                        checked={isActive}
                        value={isActive}
                        onClick={() => setIsActive(!isActive)}
                    >
                        Active
                    </Checkbox>
                </FlexboxGrid>
                <br />

                <div>Photo</div>

                {fileList.length ? (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        multiple={false}
                        fileList={fileList}
                        onRemove={removePickImage}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                ) : (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        multiple={false}
                        onRemove={removePickImage}
                        disabled={previewPicture}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                )}

                <br />
                <br />

                <div>Logo</div>

                {fileListLogo.length ? (
                    <Uploader
                        onChange={handleSelectPictureLogo}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        multiple={false}
                        fileList={fileListLogo}
                        disabled
                        onRemove={removePickImageLogo}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                ) : (
                    <Uploader
                        onChange={handleSelectPictureLogo}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        multiple={false}
                        disabled={previewPictureLogo}
                        onRemove={removePickImageLogo}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                )}

                <FormGroup>
                    <ControlLabel>Button text</ControlLabel>
                    <FormControl
                        value={buttonText}
                        onChange={(text) => setButtonText(text)}
                        name="order"
                    />
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createAffiliate} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default EditAffiliate;
