import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

const RouterLink = forwardRef(({ href: to = '', children, ...restProps }, ref) => (
    <NavLink to={to} {...restProps} ref={ref}>
        {children}
    </NavLink>
));

export default RouterLink;
