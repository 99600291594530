import { useEffect, useState } from 'react';
import {
    Button,
    ButtonToolbar,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    Checkbox,
    IconButton,
    Icon,
    Uploader,
} from 'rsuite';
import { useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import styles from './EditChallenge.module.scss';
import { getLastUrlPath } from '../../helpers/getLastUrlPath';
import { DURAITIONS, WELLNESS } from '../../constants';
import { normalizeDate } from '../../helpers/common';

const EditChallenge = () => {
    const [name, setName] = useState('');
    const [challenge, setChallenge] = useState({});
    const [description, setDescription] = useState('');
    const [author, setAuthor] = useState('');
    const [alternatives, setAlternatives] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [triggers, setTriggers] = useState([]);
    const [notes, setNotes] = useState([]);
    const [steps, setSteps] = useState([]);
    const [opportunities, setOpportunities] = useState([]);
    const [duration, setDuration] = useState('');
    const [visible, toggleVisible] = useState(false);
    const [previewPicture, setPreviewPicture] = useState('');
    const [fileList, setFileList] = useState([]);
    const [wellness, setWellness] = useState('Emotional');
    const [creators, setCreators] = useState([]);
    const [creator, setCreator] = useState('');

    const history = useHistory();

    useEffect(() => {
        const challengeId = getLastUrlPath();
        const getChallenge = async () => {
            try {
                const { data } = await API({ url: `v1/challenges/${challengeId}`, method: 'GET' });
                if (data) {
                    setName(data.name);
                    setDescription(data.description);
                    setAuthor(data.author);
                    setWellness(data.wellness);
                    data.duration ? setDuration(data.duration) : setDuration('months');
                    data.visible ? toggleVisible(data.visible) : toggleVisible(false);
                    if (data.alternatives) setAlternatives(data.alternatives);
                    if (data.opportunities) setOpportunities(data.opportunities);
                    if (data.steps) setSteps(data.steps);
                    if (data.notes) setNotes(data.notes);
                    if (data.triggers) setTriggers(data.triggers);
                    if (data.rewards) setRewards(data.rewards);
                    if (data.creator) setCreator(data.creator.name);

                    setChallenge(data);

                    if (data.photo) {
                        setFileList([
                            {
                                name: '1png',
                                fileKey: 1,
                                url: data.photo,
                            },
                        ]);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        getChallenge();
        getAuthors();
    }, []);

    const getAuthors = async () => {
        try {
            const { data } = await API({ url: 'v1/blog/article-authors', method: 'GET' });
            data.unshift({ name: '', id: 0 });
            setCreators(data);
        } catch (e) {
            console.log(e);
        }
    };

    const pickedDuration = (duration) => {
        const [pickedDuration] = DURAITIONS.filter((item) => item === duration);
        if (pickedDuration) {
            setDuration(pickedDuration);
        }
    };

    const changeVisible = (_, checked) => {
        toggleVisible(checked);
    };

    const setAlternative = (val, idx) => {
        const _alternatives = [...alternatives];
        _alternatives[idx].name = val;
        setAlternatives(_alternatives);
    };

    const setOpportunitie = (val, idx) => {
        const _opportunities = [...opportunities];
        _opportunities[idx].name = val;
        setOpportunities(_opportunities);
    };

    const changeReward = (val, idx) => {
        const _rewards = [...rewards];
        _rewards[idx].name = val;
        setRewards(_rewards);
    };

    const changeTrigger = (val, idx) => {
        const _triggers = [...triggers];
        _triggers[idx].name = val;
        setTriggers(_triggers);
    };

    const changeStep = (val, idx) => {
        const _steps = [...steps];
        _steps[idx].name = val;
        setSteps(_steps);
    };

    const changeStepDesc = (val, idx) => {
        const _steps = [...steps];
        _steps[idx].description = val;
        setSteps(_steps);
    };

    const changeNote = (val, idx) => {
        const _notes = [...notes];
        _notes[idx].name = val;
        setNotes(_notes);
    };

    const handleSelectPicture = (files) => {
        if (files.length > 0) {
            return setPreviewPicture(files[0].blobFile);
        }
    };

    const removePickImage = () => {
        setFileList([]);
        setPreviewPicture('');
    };

    const createAuthorRequestBody = () => {
        const requestBody = new FormData();
        requestBody.append('id', challenge.id);
        requestBody.append('oldPhoto', challenge.photo);
        if (previewPicture) requestBody.append('photo', previewPicture);
        return requestBody;
    };

    const changeData = async () => {
        try {
            const findedCreator = creators.find((c) => c.name === creator);

            const newChallengeData = {
                ...challenge,
                name,
                description,
                author,
                duration,
                visible,
                alternatives,
                rewards,
                notes,
                triggers,
                steps,
                opportunities,
                wellness,
                creator: findedCreator.name ? findedCreator : null,
            };

            const { data } = await API({
                url: 'v1/challenges/admin',
                method: 'PUT',
                data: newChallengeData,
            });

            if (previewPicture) {
                const data = createAuthorRequestBody();
                await API({
                    url: 'v1/challenges/admin/photo/upload',
                    method: 'PUT',
                    data,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'application/json',
                        type: 'formData',
                    },
                });
            }

            if (data && data.updated) {
                history.push('/challenges/list');
            }
        } catch (e) {
            console.error(e);
        }
    };

    const HABIT_TYPE = challenge.type === 'habit';
    const GOAL_TYPE = challenge.type === 'goal';
    // const IDEALDAY_TYPE = challenge.type === 'ideal-day';

    return (
        <div className={styles.body}>
            <h2>Edit Challenge</h2>

            <FormGroup>
                {!fileList.length ? (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        onRemove={removePickImage}
                        multiple={false}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                ) : (
                    <Uploader
                        onChange={handleSelectPicture}
                        listType="picture"
                        accept=".png,.jpg,.jpeg,.webp"
                        autoUpload={false}
                        fileList={fileList}
                        onRemove={removePickImage}
                        multiple={false}
                    >
                        <button>
                            <Icon icon="camera-retro" size="lg" />
                        </button>
                    </Uploader>
                )}
            </FormGroup>

            <Form>
                <FormGroup>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl value={name} onChange={(val) => setName(val)} name="name" />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Description</ControlLabel>
                    <FormControl
                        value={description}
                        onChange={(val) => setDescription(val)}
                        name="description"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Author</ControlLabel>
                    <select value={author} onChange={(e) => {
                        setCreator(e.target.value)
                        setAuthor(e.target.value)
                    }}>
                        {creators.map((item, index) => {
                            return <option key={index}>{item.name}</option>;
                        })}
                    </select>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Duration</ControlLabel>
                    <select value={duration} onChange={(id) => pickedDuration(id.target.value)}>
                        {DURAITIONS.map((item, index) => {
                            return <option key={index}>{item}</option>;
                        })}
                    </select>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Wellness</ControlLabel>
                    <select value={wellness} onChange={(id) => setWellness(id.target.value)}>
                        {WELLNESS.map((item, index) => {
                            return <option key={index}>{item}</option>;
                        })}
                    </select>
                </FormGroup>

                {HABIT_TYPE ? (
                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                        <ControlLabel>Alternatives:</ControlLabel>

                        <IconButton
                            style={{ width: '30px', marginBottom: '10px' }}
                            onClick={() => {
                                const _alternatives = [...alternatives];
                                const maxIndex = Math.max.apply(
                                    null,
                                    alternatives.map((item) => item.index),
                                );
                                _alternatives.push({ name: '', index: maxIndex + 1 });
                                setAlternatives(_alternatives);
                            }}
                            icon={<Icon icon={'plus-circle'} />}
                            color={'green'}
                            title={'Open'}
                            size={'sm'}
                        />

                        {alternatives.map((i, idx) => {
                            return (
                                <div key={idx}>
                                    <span style={{ marginRight: '20px' }}>{idx + 1}</span>
                                    <FormControl
                                        style={{ width: '1000px' }}
                                        value={i.name}
                                        onChange={(val) => setAlternative(val, idx)}
                                        name="alternative"
                                    />
                                    <IconButton
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => {
                                            const filtered = alternatives.filter(
                                                (item) => item.name !== i.name,
                                            );
                                            setAlternatives(filtered);
                                        }}
                                        icon={<Icon icon={'close-circle'} />}
                                        color={'red'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                </div>
                            );
                        })}
                    </FormGroup>
                ) : null}

                {GOAL_TYPE ? (
                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                        <ControlLabel>Steps:</ControlLabel>

                        <IconButton
                            style={{ width: '30px', marginBottom: '10px' }}
                            onClick={() => {
                                const _steps = [...steps];
                                const maxIndex = Math.max.apply(
                                    null,
                                    _steps.map((item) => item.index),
                                );
                                _steps.push({ name: '', description: '', index: maxIndex + 1 });
                                setSteps(_steps);
                            }}
                            icon={<Icon icon={'plus-circle'} />}
                            color={'green'}
                            title={'Open'}
                            size={'sm'}
                        />

                        {steps.map((i, idx) => {
                            return (
                                <div key={idx}>
                                    <div>{idx + 1}.</div>
                                    <span style={{ marginRight: '55px' }}>Step name</span>
                                    <FormControl
                                        style={{ width: '1000px' }}
                                        value={i.name}
                                        onChange={(val) => changeStep(val, idx)}
                                        name="step"
                                    />
                                    <div>
                                        <span style={{ marginRight: '20px' }}>
                                            Step description
                                        </span>
                                        <FormControl
                                            style={{ width: '1000px', marginTop: '10px' }}
                                            value={i.description}
                                            onChange={(val) => changeStepDesc(val, idx)}
                                            name="step"
                                        />

                                        <IconButton
                                            style={{ marginLeft: '10px' }}
                                            onClick={() => {
                                                const filtered = steps.filter(
                                                    (item) => item.name !== i.name,
                                                );

                                                setSteps(filtered);
                                            }}
                                            icon={<Icon icon={'close-circle'} />}
                                            color={'red'}
                                            title={'Open'}
                                            size={'sm'}
                                        />
                                        <hr />
                                    </div>
                                </div>
                            );
                        })}
                    </FormGroup>
                ) : null}

                {GOAL_TYPE ? (
                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                        <ControlLabel>Opportunity Cost:</ControlLabel>

                        <IconButton
                            style={{ width: '30px', marginBottom: '10px' }}
                            onClick={() => {
                                const _opportunities = [...opportunities];
                                const maxIndex = Math.max.apply(
                                    null,
                                    _opportunities.map((item) => item.index),
                                );
                                _opportunities.push({ name: '', index: maxIndex + 1 });
                                setOpportunities(_opportunities);
                            }}
                            icon={<Icon icon={'plus-circle'} />}
                            color={'green'}
                            title={'Open'}
                            size={'sm'}
                        />

                        {opportunities.map((i, idx) => {
                            return (
                                <div key={idx}>
                                    <span style={{ marginRight: '20px' }}>{idx + 1}</span>
                                    <FormControl
                                        style={{ width: '1000px' }}
                                        value={i.name}
                                        onChange={(val) => setOpportunitie(val, idx)}
                                        name="opportunity"
                                    />
                                    <IconButton
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => {
                                            const filtered = opportunities.filter(
                                                (item) => item.name !== i.name,
                                            );
                                            setOpportunities(filtered);
                                        }}
                                        icon={<Icon icon={'close-circle'} />}
                                        color={'red'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                </div>
                            );
                        })}
                    </FormGroup>
                ) : null}

                {HABIT_TYPE ? (
                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                        <ControlLabel>Rewards:</ControlLabel>

                        <IconButton
                            style={{ width: '30px', marginBottom: '10px' }}
                            onClick={() => {
                                const _rewards = [...rewards];
                                const maxIndex = Math.max.apply(
                                    null,
                                    _rewards.map((item) => item.index),
                                );
                                _rewards.push({ name: '', index: maxIndex + 1 });
                                setRewards(_rewards);
                            }}
                            icon={<Icon icon={'plus-circle'} />}
                            color={'green'}
                            title={'Open'}
                            size={'sm'}
                        />

                        {rewards.map((i, idx) => {
                            return (
                                <div key={idx}>
                                    <span style={{ marginRight: '20px' }}>{idx + 1}</span>
                                    <FormControl
                                        style={{ width: '1000px' }}
                                        value={i.name}
                                        onChange={(val) => changeReward(val, idx)}
                                        name="reward"
                                    />
                                    <IconButton
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => {
                                            const filtered = rewards.filter(
                                                (item) => item.name !== i.name,
                                            );

                                            setRewards(filtered);
                                        }}
                                        icon={<Icon icon={'close-circle'} />}
                                        color={'red'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                </div>
                            );
                        })}
                    </FormGroup>
                ) : null}

                {HABIT_TYPE ? (
                    <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                        <ControlLabel>Triggers:</ControlLabel>

                        <IconButton
                            style={{ width: '30px', marginBottom: '10px' }}
                            onClick={() => {
                                const _triggers = [...triggers];
                                const maxIndex = Math.max.apply(
                                    null,
                                    _triggers.map((item) => item.index),
                                );
                                _triggers.push({ name: '', index: maxIndex + 1 });
                                setTriggers(_triggers);
                            }}
                            icon={<Icon icon={'plus-circle'} />}
                            color={'green'}
                            title={'Open'}
                            size={'sm'}
                        />

                        {triggers.map((i, idx) => {
                            return (
                                <div key={idx}>
                                    <span style={{ marginRight: '20px' }}>{idx + 1}</span>
                                    <FormControl
                                        style={{ width: '1000px' }}
                                        value={i.name}
                                        onChange={(val) => changeTrigger(val, idx)}
                                        name="trigger"
                                    />
                                    <IconButton
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => {
                                            const filtered = triggers.filter(
                                                (item) => item.name !== i.name,
                                            );
                                            setTriggers(filtered);
                                        }}
                                        icon={<Icon icon={'close-circle'} />}
                                        color={'red'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                </div>
                            );
                        })}
                    </FormGroup>
                ) : null}

                <FormGroup>
                    <ControlLabel>Notes</ControlLabel>

                    <IconButton
                        style={{ width: '30px', marginBottom: '10px' }}
                        onClick={() => {
                            const _notes = [...notes];
                            const maxIndex = _notes.length
                                ? Math.max.apply(
                                      null,
                                      _notes.map((item) => item.index),
                                  )
                                : 1;
                            _notes.push({ name: '', index: maxIndex + 1 });
                            setNotes(_notes);
                        }}
                        icon={<Icon icon={'plus-circle'} />}
                        color={'green'}
                        title={'Open'}
                        size={'sm'}
                    />

                    <br />

                    {notes.map((i, idx) => {
                        return (
                            <div key={idx} className={styles.noteWrapper}>
                                <textarea
                                    className={styles.notes}
                                    onChange={(e) => changeNote(e.target.value, idx)}
                                    key={idx}
                                >
                                    {i.name}
                                </textarea>
                                <div className={styles.deleteNoteWrapper}>
                                    <IconButton
                                        onClick={() => {
                                            const filtered = notes.filter(
                                                (item) => item.name !== i.name,
                                            );

                                            setNotes([]);

                                            setTimeout(() => {
                                                setNotes(filtered);
                                            }, 10);
                                        }}
                                        icon={<Icon icon={'close-circle'} />}
                                        color={'red'}
                                        title={'Open'}
                                        size={'sm'}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </FormGroup>

                {HABIT_TYPE && challenge.type_habit && (
                    <FormGroup>
                        <span>Habit type: </span>
                        <strong>{challenge.type_habit || 0}</strong>
                    </FormGroup>
                )}

                <FormGroup>
                    <span>Estimate: </span>
                    <strong>{challenge.estimate || 0}</strong>
                </FormGroup>

                <FormGroup>
                    <span>Join: </span>
                    <strong>{challenge.join_count || 0}</strong>
                </FormGroup>

                <FormGroup>
                    <span>Like: </span>
                    <strong>{challenge.like_count || 0}</strong>
                </FormGroup>

                <FormGroup>
                    <span>Created: </span>
                    <strong>{normalizeDate(challenge.created_at)}</strong>
                </FormGroup>

                <Checkbox onChange={changeVisible} checked={visible}>
                    Visible
                </Checkbox>
                <br />

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={changeData} appearance="primary">
                            Save
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
};

export default EditChallenge;
