import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button, Input } from 'rsuite';
import { API } from '../../../helpers/api';
import { getLastUrlPath } from '../../../helpers/getLastUrlPath';

const EditInspiraton = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [affiliateId, setAffiliateId] = useState([]);

    const history = useHistory();

    useEffect(() => {
        API({
            url: `v1/journey/admin/inspiration/${getLastUrlPath()}`,
            method: 'GET',
        }).then((res) => {
            if (res.data) {
                setTitle(res.data.title);
                setSubtitle(res.data.body);
                setAffiliateId(res.data.id);
            }
        });
    }, []);

    const validateFields = () => {
        setTitleError(false);

        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }
        if (isError) {
            return false;
        }

        return true;
    };

    const createInspiraton = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        await API({
            url: 'v1/journey/admin/inspiration',
            method: 'PUT',
            data: { id: affiliateId, title, body: subtitle },
        });

        history.push('/inspiration/list');
    };

    return (
        <div>
            <h2>Edit inspiration</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Author</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Text</ControlLabel>

                    <textarea
                        style={{ width: '700px' }}
                        rows={10}
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                    ></textarea>
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createInspiraton} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default EditInspiraton;
