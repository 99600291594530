import { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Icon, IconButton, Table, FlexboxGrid, Checkbox } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Confirm from '../../components/common/Confirm/Confirm';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';

const { Column, Cell, HeaderCell } = Table;
let take = 1000;
let skip = 0;

const Users = () => {
    const [users, setUsers] = useState();
    const [reservUser, setReserveUsers] = useState();
    const [userCount, setUserCount] = useState();
    const [isFirstPage, setIsFirstPage] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [onDeleteUserId, setDeleteUserId] = useState(false);
    const [isOnlyPremium, setIsOnlyPremium] = useState(false);
    const [isOnlyInfinity, setIsOnlyInfinity] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        API({ url: `v1/users?take=${take}&skip=${take * skip}`, method: 'GET' }).then((res) => {
            if (res.data.users.some((item) => item.id === 1)) {
                setIsFirstPage(true);
            } else {
                setIsFirstPage(false);
            }

            for (const user of res.data.users) {
                if (user.phone) {
                    user.phone = user.code_country + user.phone;
                }
            }

            setUsers(res.data.users);
            setReserveUsers(res.data.users);
            setUserCount(res.data.count);
        });
    };

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const modalAgreeHandler = () => {
        API({ url: 'v1/users/' + onDeleteUserId, method: 'DELETE' }).then((res) => {
            getUsers();
        });
        setModalOpen(false);
    };

    const deleteUsers = (id) => {
        setDeleteUserId(id);
        setModalOpen(true);
    };

    const normalizeDate = (date) => {
        return date.slice(0, 10);
    };

    const nextPageHanlder = () => {
        skip++;
        getUsers();
    };

    const prevPageHanlder = () => {
        skip--;
        getUsers();
    };

    const onlyPremium = () => {
        setIsOnlyPremium(!isOnlyPremium);
        if (!isOnlyPremium) {
            const onlyPremiumUsers = users.filter((item) => item.plan === 'Premium');
            setUsers(onlyPremiumUsers);
        } else {
            setUsers(reservUser);
        }
    };

    const onlyInfinity = () => {
        setIsOnlyInfinity(!isOnlyInfinity);
        if (!isOnlyInfinity) {
            const onlyInfinity = users.filter((item) => item.is_infinity_subscribe);
            setUsers(onlyInfinity);
        } else {
            setUsers(reservUser);
        }
    };

    const changeInfinitySubscribe = (userId) => {
        API({
            url: 'v1/users/account/isinfinity',
            method: 'POST',
            data: { userId },
        }).then((res) => {
            getUsers();
        });
    };

    const changeInfinityMiniSubscribe = (userId) => {
        API({
            url: 'v1/users/account/isinfinity/mini',
            method: 'POST',
            data: { userId },
        }).then((res) => {
            getUsers();
        });
    };

    return (
        <div className={styles.container}>
            <FlexboxGrid>
                <h2>Users</h2>
                <Button
                    style={{ marginTop: '10px', marginLeft: '10px' }}
                    onClick={() => history.push('/users/create')}
                    color="green"
                >
                    Create new Users
                </Button>
                <div className={styles.countUser}>
                    Total users: <strong> {userCount}</strong>
                </div>
                <div className={styles.pageContainer}>
                    <Button onClick={prevPageHanlder} disabled={isFirstPage} color="blue">
                        Prev page
                    </Button>
                    <Button onClick={nextPageHanlder} color="blue">
                        Next page
                    </Button>
                </div>
                <div className={styles.checkbox}>
                    <Checkbox onClick={onlyPremium}>Only premium</Checkbox>
                </div>
                <div className={styles.checkbox}>
                    <Checkbox onClick={onlyInfinity}>Only infinity</Checkbox>
                </div>
            </FlexboxGrid>
            <Table style={{ width: 1700 }} data={users} autoHeight rowHeight={60}>
                <Column width={50} verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>

                <Column width={120} verticalAlign={'middle'}>
                    <HeaderCell>Phone number</HeaderCell>
                    <Cell dataKey={'phone'} />
                </Column>

                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Username</HeaderCell>
                    <Cell dataKey={'nickName'} />
                </Column>

                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey={'email'} />
                </Column>

                {/* <Column verticalAlign={'middle'}>
                    <HeaderCell>Gender</HeaderCell>
                    <Cell dataKey={'gender'} />
                </Column> */}

                <Column verticalAlign={'middle'}>
                    <HeaderCell>Photo</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            if (rowData.photoUrl) {
                                return (
                                    <div
                                        className={styles.photo}
                                        style={{ backgroundImage: `url(${rowData.photoUrl})` }}
                                    ></div>
                                );
                            }
                        }}
                    </Cell>
                </Column>

                <Column verticalAlign={'middle'}>
                    <HeaderCell>Plan</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return (
                                <div
                                    className={rowData.plan === 'Basic' ? styles.basic : styles.pro}
                                >
                                    {rowData.plan}
                                </div>
                            );
                        }}
                    </Cell>
                </Column>

                <Column width={80} verticalAlign={'middle'}>
                    <HeaderCell style={{ whiteSpace: 'wrap' }}>Period</HeaderCell>
                    <Cell dataKey={'subscribe_period'} />
                </Column>

                <Column width={70} verticalAlign={'middle'}>
                    <HeaderCell>Infinity</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return (
                                <Checkbox
                                    checked={rowData.is_infinity_subscribe}
                                    onClick={() => changeInfinitySubscribe(rowData.id)}
                                ></Checkbox>
                            );
                        }}
                    </Cell>
                </Column>

                <Column width={110} verticalAlign={'middle'}>
                    <HeaderCell>Infinity mini</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return (
                                <Checkbox
                                    checked={rowData.is_infinity_subscribe_mini}
                                    onClick={() => changeInfinityMiniSubscribe(rowData.id)}
                                ></Checkbox>
                            );
                        }}
                    </Cell>
                </Column>

                <Column verticalAlign={'middle'}>
                    <HeaderCell>Reports</HeaderCell>
                    <Cell dataKey={'reports_count'} />
                </Column>
                <Column verticalAlign={'middle'}>
                    <HeaderCell>Followers</HeaderCell>
                    <Cell dataKey={'followers_count'} />
                </Column>
                <Column verticalAlign={'middle'}>
                    <HeaderCell>Following</HeaderCell>
                    <Cell dataKey={'following_count'} />
                </Column>
                <Column width={250} verticalAlign={'middle'}>
                    <HeaderCell>Bio</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return <div title={rowData.bio}>{rowData.bio}</div>;
                        }}
                    </Cell>
                </Column>

                <Column verticalAlign={'middle'}>
                    <HeaderCell>Created</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            if (rowData.createdAt) {
                                return <div>{normalizeDate(rowData.createdAt)}</div>;
                            }
                        }}
                    </Cell>
                </Column>

                <Column width={80} fixed={'right'} verticalAlign={'middle'}>
                    <HeaderCell>Action</HeaderCell>

                    <Cell>
                        {(rowData) => {
                            // const handleEdit = () => {
                            //     const path = [history.location.pathname, 'edit', rowData.id];
                            //     history.push(path.join('/'));
                            // };

                            return (
                                <ButtonToolbar>
                                    {/* <IconButton
                                        onClick={handleEdit}
                                        icon={<Icon icon={'pencil'} />}
                                        color={'blue'}
                                        title={'Edit'}
                                        size={'sm'}
                                    /> */}
                                    <IconButton
                                        onClick={deleteUsers.bind(this, rowData.id)}
                                        icon={<Icon icon={'trash'} />}
                                        color={'red'}
                                        title={'Remove'}
                                        size={'sm'}
                                    />
                                </ButtonToolbar>
                            );
                        }}
                    </Cell>
                </Column>
            </Table>

            <br />
            <br />
            <br />

            <Confirm
                onAgree={modalAgreeHandler}
                onDisagree={modalDisagreeHandler}
                isOpen={isModalOpen}
            />
        </div>
    );
};

export default Users;
