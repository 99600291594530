import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button } from 'rsuite';
import { API } from '../../../helpers/api';
import styles from '../../Community/styles.module.scss';

const CreateTip = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [tip, setTip] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [adminAuthors, setAdminAuthors] = useState([]);
    const [pickedUserAuthor, setPickedUserAuthor] = useState(null);
    const [pickedPhoto, setPickedPhoto] = useState('');
    const history = useHistory();

    const getUserAuthors = () => {
        API({ url: 'v1/users/admin/authors', method: 'GET' }).then((res) => {
            res.data.users.unshift({});
            setAdminAuthors(res.data.users);
        });
    };

    useEffect(() => {
        getUserAuthors();
    }, []);

    const validateFields = () => {
        setTitleError(false);

        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }

        if (isError) {
            return false;
        }

        return true;
    };

    const createTip = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        await API({
            url: 'v1/journey/admin/tips',
            method: 'POST',
            data: {
                title,
                subtitle,
                author_photo: pickedPhoto,
                body: tip,
            },
        });

        history.push('/tips/list');
    };

    return (
        <div>
            <h2>Create Tip</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Subtitle</ControlLabel>
                    <FormControl
                        value={subtitle}
                        onChange={(text) => setSubtitle(text)}
                        name="subtitle"
                    />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                <p>Authors</p>
                <select
                    value={pickedUserAuthor}
                    onChange={(e) => {
                        setPickedUserAuthor(e.target.value);
                        const author = adminAuthors.find((i) => i.name === e.target.value);

                        if (author) {
                            setPickedPhoto(author.image_link);
                        } else {
                            setPickedPhoto('');
                        }
                    }}
                >
                    {adminAuthors.map((item, index) => {
                        return <option key={index}>{item.name}</option>;
                    })}
                </select>

                <br />
                <br />

                <>
                    {pickedPhoto ? (
                        <div
                            className={styles.photo}
                            style={{ backgroundImage: `url(${pickedPhoto})` }}
                        ></div>
                    ) : (
                        <div></div>
                    )}
                </>
                <FormGroup>
                    <ControlLabel>Tip</ControlLabel>
                    <textarea
                        style={{ width: '700px' }}
                        rows={10}
                        onChange={(e) => setTip(e.target.value)}
                        value={tip}
                    ></textarea>
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createTip} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default CreateTip;
