import { useEffect, useState } from 'react';
import { Table, FlexboxGrid, Button } from 'rsuite';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';
import { PERSONAL_NOTIFICATION_TYPES, VIOLATION_NOTICE } from '../../constants';

const { Column, Cell, HeaderCell } = Table;
let limit = 50;
let offset = 0;

const ReportCommentList = () => {
    const [reports, setReports] = useState();

    const getReportComments = async () => {
        const { data } = await API({
            url: `v1/community/admin/report/comments?limit=${limit}&offset=${limit * offset}`,
            method: 'GET',
        });

        setReports(data.reports);
    };

    useEffect(() => {
        getReportComments();
    }, []);

    const sendNotice = async (userId) => {
        try {
            await API({
                url: 'v1/users/notice/report',
                method: 'POST',
                data: {
                    user_id: userId,
                    message: VIOLATION_NOTICE,
                    type: PERSONAL_NOTIFICATION_TYPES.violationNotice,
                },
            });
            alert('Notice sent!');
        } catch (e) {
            console.error(e);
        }
    };

    const deleteComment = async (commentId) => {
        try {
            await API({
                url: `v1/community/admin/comment/${commentId}`,
                method: 'DELETE',
            });
            alert('Comment deleted');
            getReportComments();
        } catch (e) {
            console.error(e);
        }
    };

    const blockAccount = async (userId) => {
        try {
            await API({
                url: 'v1/users/account/block',
                method: 'POST',
                data: {
                    userId,
                },
            });
            let filteredReports = reports.filter((item) => item.user_data.id === userId);

            for (const post of filteredReports) {
                post.user_is_block = !post.user_is_block;
            }

            const _reports = [...reports];
            setReports(_reports);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={styles.container}>
            <FlexboxGrid>
                <h2>Reported Comments</h2>
            </FlexboxGrid>
            <Table style={{ width: 1500 }} data={reports} autoHeight rowHeight={70}>
                <Column width={50} verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>

                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Username</HeaderCell>
                    <Cell>{(data) => <div>{data.user_data.name}</div>}</Cell>
                </Column>

                <Column width={700} verticalAlign={'middle'}>
                    <HeaderCell>Message</HeaderCell>
                    <Cell dataKey={'body'}>
                        {(rowData) => (
                            <div
                                style={{
                                    overflow: 'auto',
                                    paddingBottom: '30px',
                                    maxHeight: '200px', // Задайте необходимую высоту для включения прокрутки
                                    whiteSpace: 'pre-wrap', // Чтобы текст переносился на новую строку
                                }}
                            >
                                {rowData.body}
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Notice Action</HeaderCell>
                    <Cell>
                        {(data) => (
                            <Button
                                onClick={() => sendNotice(data.user_data.id)}
                                appearance="primary"
                            >
                                Send Notice
                            </Button>
                        )}
                    </Cell>
                </Column>
                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Block account Action</HeaderCell>
                    <Cell>
                        {(data) => (
                            <Button
                                onClick={() => blockAccount(data.user_data.id)}
                                color={data.user_is_block ? 'green' : 'red'}
                                appearance="primary"
                            >
                                {data.user_is_block ? 'Unblock account' : 'Block account'}
                            </Button>
                        )}
                    </Cell>
                </Column>
                <Column width={200} verticalAlign={'middle'}>
                    <HeaderCell>Delete comment</HeaderCell>
                    <Cell>
                        {(data) => (
                            <Button
                                onClick={() => deleteComment(data.id)}
                                color={data.user_is_block ? 'green' : 'red'}
                                appearance="primary"
                            >
                                Delete comment
                            </Button>
                        )}
                    </Cell>
                </Column>
            </Table>
        </div>
    );
};

export default ReportCommentList;
