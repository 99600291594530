import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Icon, IconButton, Table, FlexboxGrid } from 'rsuite';
import Confirm from '../../../components/common/Confirm/Confirm';
import { API } from '../../../helpers/api';
import { normalizeDate } from '../../../helpers/common';

const { Column, Cell, HeaderCell } = Table;

const Karmas = () => {
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [onDeleteAffiliateId, setOnDeleteAffiliateId] = useState(false);

    const getAffiliate = () => {
        API({ url: 'v1/journey/karma/rules', method: 'GET' }).then((res) => {
            setItems(res.data.rules.reverse());
        });
    };

    useEffect(() => {
        getAffiliate();
    }, []);

    const handleCreateAffiliate = () => {
        history.push('/karma/create');
    };

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const modalAgreeHandler = () => {
        API({ url: 'v1/journey/karma/rules/' + onDeleteAffiliateId, method: 'DELETE' }).then(
            (res) => {
                getAffiliate();
            },
        );
        setModalOpen(false);
    };

    const deleteArticle = (id) => {
        setOnDeleteAffiliateId(id);
        setModalOpen(true);
    };

    return (
        <div>
            <FlexboxGrid>
                <h2>Karma Rules</h2>
                <Button
                    style={{ marginTop: '10px', marginLeft: '10px' }}
                    onClick={handleCreateAffiliate}
                    color="green"
                >
                    Create
                </Button>
            </FlexboxGrid>

            <Table style={{ width: 1200 }} data={items} autoHeight rowHeight={55}>
                <Column width={50} verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>

                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey={'rule_name'} />
                </Column>

                <Column width={700} verticalAlign={'middle'}>
                    <HeaderCell>Rules</HeaderCell>
                    <Cell dataKey={'rules'} />
                </Column>
                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Created</HeaderCell>
                    <Cell>
                        {(rowData) => {
                            return <div>{normalizeDate(rowData.created_at)}</div>;
                        }}
                    </Cell>
                </Column>

                <Column width={80} fixed={'right'} verticalAlign={'middle'}>
                    <HeaderCell>Action</HeaderCell>

                    <Cell>
                        {(rowData) => {
                            const handleEdit = () => {
                                // const path = [history.location.pathname, 'edit', rowData.id];
                                history.push(`edit/${rowData.id}`);
                            };

                            return (
                                <ButtonToolbar>
                                    <IconButton
                                        onClick={handleEdit}
                                        icon={<Icon icon={'pencil'} />}
                                        color={'blue'}
                                        title={'Edit'}
                                        size={'sm'}
                                    />
                                    <IconButton
                                        onClick={deleteArticle.bind(this, rowData.id)}
                                        icon={<Icon icon={'trash'} />}
                                        color={'red'}
                                        title={'Remove'}
                                        size={'sm'}
                                    />
                                </ButtonToolbar>
                            );
                        }}
                    </Cell>
                </Column>
            </Table>

            <br />
            <br />
            <br />
            <br />
            <br />

            <Confirm
                onAgree={modalAgreeHandler}
                onDisagree={modalDisagreeHandler}
                isOpen={isModalOpen}
            />
        </div>
    );
};

export default Karmas;
