import { useEffect, useState } from 'react';
import { FormGroup, ControlLabel, Button } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { API } from '../../helpers/api';
import { getLastUrlPath } from '../../helpers/getLastUrlPath';
import styles from './ChallengesItem.module.scss';

const ChallengesItem = () => {
    const [challenge, setChallenge] = useState({});
    const history = useHistory();

    useEffect(() => {
        const typeEntity = getLastUrlPath(2);
        const idEntity = getLastUrlPath(1);

        if (typeEntity === 'habit') {
            API({ url: `v1/challenges/admin/habit/${idEntity}`, method: 'GET' })
                .then(({ data }) => {
                    setChallenge(data);
                })
                .catch((e) => console.error(e));
        }
        if (typeEntity === 'goal') {
            API({ url: `v1/challenges/admin/goal/${idEntity}`, method: 'GET' })
                .then(({ data }) => {
                    setChallenge(data);
                })
                .catch((e) => console.error(e));
        }
    }, []);

    const acceptEntity = () => {
        const typeEntity = getLastUrlPath(2);
        const entityId = getLastUrlPath(1);

        API({
            url: `v1/challenges/admin/${typeEntity}/create`,
            method: 'POST',
            data: {
                basedId: Number(entityId),
            },
        })
            .then(({ data }) => {
                history.push('/challenges/verification');
            })
            .catch((e) => console.error(e));
    };

    const declineEntity = () => {
        const typeEntity = getLastUrlPath(2);
        const idEntity = getLastUrlPath(1);

        API({ url: `v1/challenges/admin/decline/${idEntity}`, method: 'PUT', data: { typeEntity } })
            .then(({ data }) => {
                history.push('/challenges/verification');
            })
            .catch((e) => console.error(e));
    };

    const typeEntity = getLastUrlPath(2);

    return (
        <div>
            <h2>Name: {challenge.name}</h2>
            <div className={styles.desc}>
                Description: {challenge.description || challenge.routine}
            </div>
            <div className={styles.desc}>Type: {typeEntity}</div>
            <br />
            {challenge.photo && (
                <div className={styles.img}>
                    <img src={challenge.photo} alt="alt" />
                </div>
            )}

            <br />
            <br />

            {challenge.triggers && challenge.triggers.length ? (
                <>
                    <div>TRIGGERS:</div>
                    <div className={styles.triggerCont}>
                        {challenge.triggers.map((item, index) => {
                            return (
                                <div className={styles.itemStyle} key={index}>
                                    {index + 1}. {item.name}
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}

            <br />

            {challenge.rewards && challenge.rewards.length ? (
                <>
                    <div>REWARDS:</div>
                    <div className={styles.triggerCont}>
                        {challenge.rewards.map((item, index) => {
                            return (
                                <div className={styles.itemStyle} key={index}>
                                    {index + 1}. {item.name}
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}

            <br />

            {challenge.alternatives && challenge.alternatives.length ? (
                <>
                    <div>ALTERNATIVES:</div>
                    <div className={styles.triggerCont}>
                        {challenge.alternatives.map((item, index) => {
                            return (
                                <div className={styles.itemStyle} key={index}>
                                    {index + 1}. {item.name}
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}

            <br />

            {challenge.steps && challenge.steps.length ? (
                <>
                    <div>STEPS:</div>
                    <div className={styles.stepsCont}>
                        {challenge.steps.map((item, index) => {
                            return (
                                <div className={styles.itemStyle} key={index}>
                                    {index + 1}. {item.name}
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}

            <br />

            {challenge.opportunities && (
                <>
                    <div>OPPORTUNITIES:</div>
                    <div className={styles.triggerCont}>
                        {challenge.opportunities.map((item, index) => {
                            return (
                                <div className={styles.itemStyle} key={index}>
                                    {index + 1}. {item.name}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            <br />

            {challenge.tags && (
                <>
                    <div>TAGS:</div>
                    <div className={styles.triggerCont}>
                        {challenge.tags.map((item, index) => {
                            return (
                                <div className={styles.itemStyle} key={index}>
                                    {index + 1}. {item.name}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            <br />

            {challenge.notes && challenge.notes.length ? (
                <>
                    <div>NOTES:</div>
                    <div className={styles.noteCont}>
                        {challenge.notes.map((item, index) => {
                            return (
                                <div className={styles.noteElem} key={index}>
                                    {index + 1}. {item.name}
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}

            <div className={styles.btnCont}>
                <Button onClick={acceptEntity} color="green" appearance="primary">
                    Accept
                </Button>
                <Button onClick={declineEntity} color="red" appearance="primary">
                    Decline
                </Button>
            </div>
        </div>
    );
};

export default ChallengesItem;
