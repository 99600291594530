import { ChallengesList } from '../../modules/Challenges';
import { useHistory } from 'react-router-dom';
import { IconButton, Icon } from 'rsuite';

const Challenges = () => {
    const history = useHistory();
    return (
        <div>
            <div style={{ display: 'flex' }}>
                <h2>Challenges</h2>
                <div style={{ marginTop: '11px', marginLeft: '10px' }}>
                    <IconButton
                        onClick={() => history.push('/challenges/new')}
                        icon={<Icon icon={'plus-circle'} />}
                        color={'green'}
                        title={'Open'}
                        size={'sm'}
                    />
                </div>
            </div>

            <ChallengesList />
        </div>
    );
};

export { Challenges };
