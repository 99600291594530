import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button, Input } from 'rsuite';
import { API } from '../../../helpers/api';

const CreateInspiration = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const history = useHistory();

    const validateFields = () => {
        setTitleError(false);

        let isError = false;

        if (!title) {
            setTitleError(true);
            isError = true;
        }

        if (isError) {
            return false;
        }

        return true;
    };

    const createInspiration = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        await API({
            url: 'v1/journey/admin/inspiration',
            method: 'POST',
            data: {
                title,
                body: subtitle,
            },
        });

        history.push('/inspiration/list');
    };

    return (
        <div>
            <h2>Create Inspiration</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Author</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Text</ControlLabel>
                    <textarea
                        style={{ width: '700px' }}
                        rows={10}
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                    ></textarea>
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createInspiration} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <br />
                <br />
            </Form>
        </div>
    );
};

export default CreateInspiration;
