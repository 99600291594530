import { useEffect, useState } from 'react';
import { Table, FlexboxGrid, Button, IconButton, Icon } from 'rsuite';
import { useHistory } from 'react-router-dom';
import Confirm from '../../components/common/Confirm/Confirm';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';

const { Column, Cell, HeaderCell } = Table;
let limit = 300;
let offset = 0;

const Posts = () => {
    const history = useHistory();
    const [posts, setPosts] = useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [postId, setPostId] = useState(false);
    const [categories, setCategories] = useState([]);
    const [adminAuthors, setAdminAuthors] = useState([]);

    const getCategories = () => {
        API({ url: 'v1/blog/categories/admin', method: 'GET' }).then((res) => {
            const _categories = res.data.map((item) => item.alias);
            _categories.push('');
            setCategories(_categories);
        });
    };

    const getUserAuthors = () => {
        API({ url: 'v1/users/admin/authors', method: 'GET' }).then((res) => {
            setAdminAuthors(res.data.users);
        });
    };

    const getPosts = async () => {
        const { data } = await API({
            url: `v1/community/admin/posts?limit=${limit}&offset=${limit * offset}`,
            method: 'GET',
        });
        setPosts(data.posts);
    };

    useEffect(() => {
        getPosts();
        getCategories();
        getUserAuthors();
    }, []);

    const editPost = async (postId) => {
        history.push(`/community/posts/edit/${postId}`);
    };

    const modalDisagreeHandler = () => {
        setModalOpen(false);
    };

    const modalAgreeHandler = async () => {
        try {
            await API({ url: 'v1/community/admin/posts/' + postId, method: 'DELETE' });
            await getPosts();
            setModalOpen(false);
        } catch (e) {
            console.error(e);
        }
    };

    const deletePost = (postId) => {
        setPostId(postId);
        setModalOpen(true);
    };

    const changeCategory = async (categoryName, postId) => {
        try {
            await API({
                url: 'v1/community/admin/posts/categories',
                method: 'POST',
                data: { categoryName, postId },
            });
            await getPosts();
        } catch (e) {
            console.error(e);
        }
    };

    const changeAuthor = async (authorName, postId) => {
        try {
            const author = adminAuthors.find((i) => i.name === authorName);
            delete (await API({
                url: 'v1/community/admin/posts/author',
                method: 'POST',
                data: { author, postId },
            }));
            await getPosts();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={styles.container}>
            <FlexboxGrid>
                <h2>Posts</h2>
                <div style={{ marginTop: '11px', marginLeft: '15px' }}>
                    <IconButton
                        onClick={() => history.push('/community/posts/create')}
                        icon={<Icon icon={'plus-circle'} />}
                        color={'green'}
                        title={'Open'}
                        size={'sm'}
                    />
                </div>
            </FlexboxGrid>

            <Table width={1800} data={posts} autoHeight rowHeight={100}>
                <Column verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>
                <Column width={200} verticalAlign={'middle'}>
                    <HeaderCell>Category</HeaderCell>

                    <Cell>
                        {(data) => (
                            <select
                                value={data.category_name || ''}
                                onChange={async (e) =>
                                    await changeCategory(e.target.value, data.id)
                                }
                            >
                                {categories.map((item, index) => {
                                    return <option key={index}>{item}</option>;
                                })}
                            </select>
                        )}
                    </Cell>
                </Column>

                <Column width={200} verticalAlign={'middle'}>
                    <HeaderCell>Author</HeaderCell>

                    <Cell>
                        {(data) => (
                            <select
                                value={data.user_data.name || ''}
                                onChange={(e) => changeAuthor(e.target.value, data.id)}
                            >
                                {adminAuthors.map((item, index) => {
                                    return <option key={index}>{item.name}</option>;
                                })}
                            </select>
                        )}
                    </Cell>
                </Column>

                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Sender's name</HeaderCell>
                    <Cell>{(data) => <div>{data.user_data.name}</div>}</Cell>
                </Column>
                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Photo</HeaderCell>
                    <Cell>
                        {(data) => {
                            return (
                                <>
                                    {data.image_links?.length ? (
                                        <div
                                            className={styles.photo}
                                            style={{ backgroundImage: `url(${data.image_links?.[0]})` }}
                                        ></div>
                                    ) : (
                                        <div></div>
                                    )}
                                </>
                            );
                        }}
                    </Cell>
                </Column>

                <Column width={700} verticalAlign={'middle'}>
                    <HeaderCell>Message</HeaderCell>
                    <Cell>
                        {(data) => (
                            <textarea
                                disabled
                                value={data.body}
                                name="postContent"
                                rows={6}
                                cols={70}
                            />
                        )}
                    </Cell>
                </Column>

                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Edit</HeaderCell>
                    <Cell>
                        {(data) => (
                            <Button
                                style={{ width: 80 }}
                                onClick={() => editPost(data.id)}
                                appearance="primary"
                            >
                                Edit
                            </Button>
                        )}
                    </Cell>
                </Column>
                <Column width={200} verticalAlign={'middle'}>
                    <HeaderCell>Delete</HeaderCell>
                    <Cell>
                        {(data) => (
                            <Button
                                style={{ width: 80 }}
                                onClick={() => deletePost(data.id)}
                                color={data.is_block ? 'green' : 'red'}
                                appearance="primary"
                            >
                                Delete
                            </Button>
                        )}
                    </Cell>
                </Column>
            </Table>

            <br />
            <br />
            <br />

            <Confirm
                onAgree={modalAgreeHandler}
                onDisagree={modalDisagreeHandler}
                isOpen={isModalOpen}
            />
        </div>
    );
};

export default Posts;
