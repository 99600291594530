import React from 'react';
import { Sidebar as SidebarBox, Sidenav, Nav, Dropdown, Icon } from 'rsuite';
import NavItem from '../../common/NavItem/NavItem';
import DropdownItem from '../../common/DropdownItem/DropdownItem';

const Sidebar = () => {
    return (
        <SidebarBox style={{ backgroundColor: '#f7f7fa', minHeight: '100vh', marginRight: '10px' }}>
            <div>
                <Sidenav activeKey="1">
                    <Sidenav.Body>
                        <Nav>
                            <NavItem
                                href={'/dashboard'}
                                eventKey="1"
                                icon={<Icon icon="dashboard" />}
                            >
                                Dashboard
                            </NavItem>
                            <Dropdown eventKey="3" title="Blog" icon={<Icon icon="bold" />}>
                                <DropdownItem href={'/blog/article-categories'} eventKey="3-1">
                                    Categories
                                </DropdownItem>
                                <DropdownItem href={'/blog/articles'} eventKey="3-2">
                                    Articles
                                </DropdownItem>
                                <DropdownItem href={'/blog/article-authors'} eventKey="3-3">
                                    Articles authors
                                </DropdownItem>
                                <DropdownItem href={'/blog/comments'} eventKey="3-4">
                                    Comments
                                </DropdownItem>
                            </Dropdown>
                            <Dropdown
                                eventKey="7"
                                title="Users"
                                icon={<Icon icon="people-group" />}
                            >
                                <DropdownItem href={'/users'} eventKey="7-2">
                                    User list
                                </DropdownItem>
                            </Dropdown>
                            <Dropdown eventKey="4" title="Community" icon={<Icon icon="peoples" />}>
                                <DropdownItem href={'/community/posts'} eventKey="4-1">
                                    Posts
                                </DropdownItem>
                                <DropdownItem href={'/community/reported/accounts'} eventKey="4-2">
                                    Reported accounts
                                </DropdownItem>
                                <DropdownItem href={'/community/reported/posts'} eventKey="4-3">
                                    Reported posts
                                </DropdownItem>
                                <DropdownItem href={'/community/reported/comments'} eventKey="4-4">
                                    Reported comments
                                </DropdownItem>
                            </Dropdown>
                            <Dropdown
                                eventKey="8"
                                title="Affiliates"
                                icon={<Icon icon="realtime" />}
                            >
                                <DropdownItem href={'/affiliate/list'} eventKey="7-1">
                                    List
                                </DropdownItem>
                            </Dropdown>
                            <Dropdown
                                eventKey="9"
                                title="Challenges"
                                icon={<Icon icon="id-card" />}
                            >
                                <DropdownItem href={'/challenges/list'} eventKey="7-1">
                                    Challenges
                                </DropdownItem>
                                <DropdownItem href={'/challenges/verification'} eventKey="7-1">
                                    Verification
                                </DropdownItem>
                            </Dropdown>
                            <Dropdown eventKey="10" title="Journey" icon={<Icon icon="star-o" />}>
                                <DropdownItem href={'/prompt/list'} eventKey="7-1">
                                    Prompts
                                </DropdownItem>
                                <DropdownItem href={'/inspiration/list'} eventKey="7-2">
                                    Inspiration
                                </DropdownItem>
                                <DropdownItem href={'/karma/list'} eventKey="7-3">
                                    Karma
                                </DropdownItem>
                                <DropdownItem href={'/tips/list'} eventKey="7-4">
                                    Tips
                                </DropdownItem>
                            </Dropdown>
                            <Dropdown eventKey="11" title="Journal" icon={<Icon icon="table" />}>
                                <DropdownItem href={'/journal/list'} eventKey="8-1">
                                    Templates
                                </DropdownItem>
                            </Dropdown>
                            <Dropdown eventKey="12" title="System" icon={<Icon icon="windows" />}>
                                <DropdownItem href={'/system/error'} eventKey="9-1">
                                    Errors
                                </DropdownItem>
                            </Dropdown>
                            <Dropdown eventKey="13" title="Open AI" icon={<Icon icon="btc" />}>
                                <DropdownItem href={'/ai/assistant'} eventKey="10-1">
                                    AI Assistant
                                </DropdownItem>
                            </Dropdown>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </div>
        </SidebarBox>
    );
};

export default Sidebar;
