import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { slugify } from 'transliteration';
import { Form, FormGroup, FormControl, ControlLabel, ButtonToolbar, Button } from 'rsuite';
import { API } from '../../../helpers/api';

const CreateCategory = () => {
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);

    const history = useHistory();

    const validateFields = () => {
        if (!title) {
            setTitleError(true);
            return false;
        }

        return true;
    };

    const createCategory = async () => {
        if (!validateFields()) {
            return;
        }
        setTitleError(false);

        await API({
            url: 'v1/blog/article-categories',
            method: 'POST',
            data: {
                title,
                alias: slugify(title),
            },
        });

        history.push('/blog/article-categories');
    };

    return (
        <div>
            <h2>Create Category</h2>
            <Form>
                <FormGroup>
                    <ControlLabel>Title</ControlLabel>
                    <FormControl value={title} onChange={(text) => setTitle(text)} name="title" />
                    {titleError && <div style={{ color: '#ca2d2d' }}>This field is required</div>}
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={createCategory} appearance="primary">
                            Create
                        </Button>
                        <Button appearance="default" onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>
        </div>
    );
};

export default CreateCategory;
