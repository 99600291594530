import { useEffect, useState } from 'react';
import { Table, FlexboxGrid, Button } from 'rsuite';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';
import { PERSONAL_NOTIFICATION_TYPES } from '../../constants';

const { Column, Cell, HeaderCell } = Table;
let limit = 50;
let offset = 0;

const ReportAccountList = () => {
    const [reports, setReports] = useState();

    useEffect(() => {
        const getReportAccounts = async () => {
            const { data } = await API({
                url: `v1/community/admin/report/accounts?limit=${limit}&offset=${limit * offset}`,
                method: 'GET',
            });
            setReports(data.reports);
        };
        getReportAccounts();
    }, []);

    const sendNotice = async (userId) => {
        try {
            await API({
                url: 'v1/users/notice/report',
                method: 'POST',
                data: {
                    user_id: userId,
                    message: 'test',
                    type: PERSONAL_NOTIFICATION_TYPES.violationNotice,
                },
            });
            alert('Notice sent!');
        } catch (e) {
            console.error(e);
        }
    };

    const blockAccount = async (userId) => {
        try {
            await API({
                url: 'v1/users/account/block',
                method: 'POST',
                data: {
                    userId,
                },
            });
            let report = reports.find((item) => item.id === userId);
            report.is_block = !report.is_block;
            const _reports = [...reports];
            setReports(_reports);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={styles.container}>
            <FlexboxGrid>
                <h2>Reported Accounts</h2>
            </FlexboxGrid>
            <Table width={1000} data={reports} autoHeight rowHeight={100}>
                <Column verticalAlign={'middle'}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey={'id'} />
                </Column>

                <Column verticalAlign={'middle'}>
                    <HeaderCell>Username</HeaderCell>
                    <Cell dataKey={'nickName'} />
                </Column>

                <Column verticalAlign={'middle'}>
                    <HeaderCell>Reports</HeaderCell>
                    <Cell dataKey={'reports_count'} />
                </Column>
                <Column width={150} verticalAlign={'middle'}>
                    <HeaderCell>Notice Action</HeaderCell>
                    <Cell>
                        {(data) => (
                            <Button onClick={() => sendNotice(data.id)} appearance="primary">
                                Send Notice
                            </Button>
                        )}
                    </Cell>
                </Column>
                <Column width={200} verticalAlign={'middle'}>
                    <HeaderCell>Block account Action</HeaderCell>
                    <Cell>
                        {(data) => (
                            <Button
                                onClick={() => blockAccount(data.id)}
                                color={data.is_block ? 'green' : 'red'}
                                appearance="primary"
                            >
                                {data.is_block ? 'Unblock account' : 'Block account'}
                            </Button>
                        )}
                    </Cell>
                </Column>
            </Table>
        </div>
    );
};

export default ReportAccountList;
